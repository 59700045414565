import React, { useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import "./style.scss";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";

const Intimation = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Drop ID",
        field: "dropId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Collected",
        field: "Collected",
        sort: "asc",
        width: 150,
      },
      {
        label: "Remaining",
        field: "Remaining",
        sort: "asc",
        width: 150,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        id: 1,
        date: "2024-09-01",
        time: "08:00 AM",
        Collected: "10 Kg",
        Remaining: "0 Kg",
        staff: "John Doe",
        dropId: "D12345",
        type: "Missing",
        description:
          "NLK Admin changed 'PLASTIC BOTTLES & ALL OTHER METAL AND PLASTIC SCRAP' item weight from 4kg to 12kg and 15 bags to 11 bags on drop item.",
        audio: (
          <>
            <audio controls style={{ height: "35px", width: "200px" }}>
              <source src={``} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </>
        ),

        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => setModalOpen(true)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        ),
      },
      {
        id: 2,
        date: "2024-09-02",
        time: "09:30 AM",
        staff: "Jane Smith",
        dropId: "D12346",
        type: "Missing",
        description:
          "NLK Admin changed 'GLASS BOTTLES & OTHER RECYCLABLE MATERIALS' item weight from 5kg to 8kg and 10 bags to 9 bags on drop item.",
        audio: (
          <>
            <audio controls style={{ height: "35px", width: "200px" }}>
              <source src={``} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </>
        ),
        Collected: "10 Kg",
        Remaining: "0 Kg",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => setModalOpen(true)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        ),
      },
      {
        id: 3,
        date: "2024-09-03",
        time: "10:15 AM",
        staff: "Michael Brown",
        dropId: "D12347",
        type: "partially",
        description:
          "NLK Admin changed 'METAL SCRAP' item weight from 6kg to 15kg and 8 bags to 7 bags on drop item.",
        audio: (
          <>
            <audio controls style={{ height: "35px", width: "200px" }}>
              <source src={``} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </>
        ),
        Collected: "10 Kg",
        Remaining: "0 Kg",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => setModalOpen(true)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        ),
      },
      {
        id: 4,
        date: "2024-09-04",
        time: "11:00 AM",
        staff: "Emily Davis",
        dropId: "D12348",
        type: "partially",
        description:
          "NLK Admin changed 'ORGANIC WASTE' item weight from 10kg to 20kg and 12 bags to 10 bags on drop item.",
        audio: (
          <>
            <audio controls style={{ height: "35px", width: "200px" }}>
              <source src={``} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </>
        ),
        Collected: "10 Kg",
        Remaining: "0 Kg",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => setModalOpen(true)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        ),
      },
      {
        id: 5,
        date: "2024-09-05",
        time: "01:00 PM",
        staff: "Sarah Wilson",
        dropId: "D12349",
        type: "partially",
        description:
          "NLK Admin changed 'ELECTRONIC WASTE' item weight from 3kg to 5kg and 6 bags to 5 bags on drop item.",
        audio: (
          <>
            <audio controls style={{ height: "35px", width: "200px" }}>
              <source src={``} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </>
        ),
        Collected: "10 Kg",
        Remaining: "0 Kg",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => setModalOpen(true)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        ),
      },
    ],
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Intimation" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">From</Label>
                        <AvField name="fromdate" type="date" />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">To</Label>
                        <AvField name="Todate" type="date" />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <ReactSelect
                          name="Staff"
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Type</Label>
                        <ReactSelect
                          name="Customer"
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "4px" }}>
                      <div className="mt-4">
                        <Button color="danger" type="print">
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
                <MDBDataTable
                  id="intimationId"
                  responsive
                  bordered
                  data={data}
                  disableRetreatAfterSorting={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          show={modalOpen}
          toggle={handleCloseModal}
          size="lg"
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Intimation </h5>
            <button
              type="button"
              onClick={() => {
                setModalOpen(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div class="col-md-12">
                <ul className="lead-details">
                  <li>
                    Date: <label class="form-label">09-01-2024</label>
                  </li>
                  <li>
                    Time: <label class="form-label">08:00 AM</label>
                  </li>
                  <li>
                    Staff: <label class="form-label">John Doe</label>
                  </li>
                  <li>
                    Status: <label class="form-label">Completed</label>
                  </li>
                  <li>
                    Collected : <label class="form-label">12 kg</label>
                  </li>
                  <li>
                    Remaining : <label class="form-label">0 kg</label>
                  </li>
                  <li>
                    Drop ID: <label class="form-label">D12345</label>
                  </li>
                  <li>
                    Location : <label class="form-label">Kannur</label>
                  </li>
                  <li>
                    Collected By : <label class="form-label">Staff Name</label>
                  </li>
                </ul>
              </div>
              <div class="col-md-12">
                <ul className="lead-details">
                  <li>
                    Image :
                    <label className="form-label">
                      <img
                        className="me-2"
                        style={{ width: "100px", height: "100px" }}
                        src={``}
                        alt={``}
                      />
                    </label>
                  </li>
                  <li>
                    Audio :
                    <label class="form-label">
                      <audio controls style={{ height: "35px" }}>
                        <source src={``} target="_blank" type="audio/mpeg" />
                        {/* <source
                              src={`https://nodeapi.nellikkastore.com/uploads/complaintaudio/20240902154453_sample-3s.mp3`}
                              type="audio/mpeg"
                            /> */}
                        Your browser does not support the audio element.
                      </audio>
                    </label>
                  </li>
                </ul>
              </div>
              <div class="col-md-12">
                <div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Total Weight</th>
                        <th>Total Bag</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Thermocol</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr>
                      <tr>
                        <td>Plastics</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr>
                      <tr>
                        <td>Chappals</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Intimation;
