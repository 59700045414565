import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import DashboardNew from "../pages/Dashboard New/index";
import User from "../pages/User/index";
import Staff from "../pages/User/Staff";
import StaffDetails from "../pages/User/Staff/Staff Detail/StaffDetails";

import Company from "../pages/MasterSettings/Company";
import Branch from "../pages/MasterSettings/Manage Branch";
import Privilage from "../pages/MasterSettings/Privilage";
import Rules from "../pages/MasterSettings/Rules";
import Settings from "../pages/MasterSettings/Master_Setting";
import RulesApp from "../pages/MasterSettings/RulesApp";
import Appdashboard from "../pages/MasterSettings/App_dashboard-menu";
import AppmenuNew from "../pages/MasterSettings/AppmenuNew/AppmenuNew";

// Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiImageCropper from "../pages/Ui/ui-image-cropper";

// Forms
import BasicElements from "../pages/Forms/BasicElements";
import FormLayouts from "../pages/Forms/FormLayouts";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

// Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";

// Icons

import IconUnicons from "../pages/Icons/IconUnicons";
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import VerifyOtp from "../pages/Authentication/otpPage";
import ResetPassword from "../pages/Authentication/resetPassword";

// // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// DIstrict
import District from "../pages/Manage/District";

// LocalBody
import Localbody from "../pages/Manage/Localbody";
// ward
import Ward from "../pages/Manage/Ward";

// Group
import Group from "../pages/Manage/Group";
// Holiday
import Holiday from "../pages/Manage/Holiday";

import ComplaintCategory from "../pages/Manage/ComplaintCategory";
import WorkrequestCategory from "../pages/Manage/WorkRequestCategory";
import FoodwasteProcessingItems from "../pages/Manage/FoodwasteProcessingItems";

import Slider from "../pages/Manage/Slider";

// Flat
import Flat from "../pages/Manage/Flat";

// Add Customer

import AddCustomer from "../pages/Customers/AddCustomer";

import viewTariff from "../pages/Customers/viewTariff";
import QrCode from "../pages/Customers/QrCode";
import ViewCustomer from "../pages/Customers/ViewCustomer";
import ViewQrCode from "../pages/Customers/viewQr";
import AssignGroup from "../pages/Customers/assignGroup";
import AssignPackage from "../pages/Customers/AssignPackage";

// account
import AccountType from "../pages/Accounts/Account-Type";
import AccountDetails from "../pages/Accounts/AccountDetails";
import Chart from "../pages/Accounts/Chart";
import FundTransfer from "../pages/Accounts/FundTransfer";
import CreditDebit from "../pages/Accounts/CreditDebit";
import IncomeExpenses from "../pages/Accounts/IncomeExpenses";
import TransactionLog from "../pages/Accounts/TransactionLog";
import ProfitAndLoss from "../pages/Accounts/ProfitAndLoss";

// Waste Collection
import HelperCollection from "../pages/WasteCollection/HelperCollection";
import HelperDrop from "../pages/WasteCollection/HelperDrop";
import DriverCollection from "../pages/WasteCollection/DriverCollection";
import DriverDrop from "../pages/WasteCollection/DriverDrop";
import WastePlot from "../pages/WasteCollection/WastePlot";
import GroupWasteSpot from "../pages/WasteCollection/GroupWasteSpot";
import HelperReport from "../pages/WasteCollection/HelperReport/HelperReport";
import DriverReport from "../pages/WasteCollection/DriverReport/DriverReport";
import WastePlotReport from "../pages/WasteCollection/WastePlotReport/WastePlotReport";
import WasteSpotReport from "../pages/WasteCollection/WasteSpotReport/WasteSpotReport";
import LocalBodyReport from "../pages/Reports/localbodyReport";
// package
import AddTariff from "../pages/Package/AddTariff";
import AddWaste from "../pages/Package/AddWaste";

// Invoice
import ViewInvoice from "../pages/Invoice/ViewInvoice";
import AutoInvoice from "../pages/Invoice/AutoInvoice";

// Receipt
import Receipt from "../pages/Receipt";

// Customer Category
import CustomerCategory from "../pages/Manage/CustomerCategory";

// customer nextvisit
import CustomerNextvisit from "../pages/CustomerNextvisit";

import VerifyCustomer from "../pages/Customers/VerifyCustomer";
//
import Incentive from "../pages/Manage/Incentive/Incentive";
//
import ImagePopupView from "../pages/ImagePopupView";
import ManageNotification from "../pages/ManageNotification";

// Chat
import Chat from "../pages/Chat/index";

// Post
import Post from "../pages/Post/Post";

// Complaint
import AddComplaint from "../pages/Complaint/AddComplaint/AddComplaint";
import Complaints from "../pages/Complaint/Complaints";
// import StaffComplaint from "../pages/Complaint/StaffComplaint/StaffComplaint";
import StaffComplaint from "../pages/Complaint/StaffNewComplaint";
import ComplaintStatistic from "../pages/Complaint/ComplaintStatistic/ComplaintStatistic";

// Attendance
import CheckinCheckoutLog from "../pages/Attendance/CheckinCheckoutLog/CheckinCheckoutLog";
import AttendanceReport from "../pages/Attendance/Report/Report";
import AttendanceSummary from "../pages/Attendance/attendanceSummary";

// Salary
import CalculateSalary from "../pages/Salary Module/Salary Calculation/Salary_Calculation";
import MonthlyPaidSalary from "../pages/Salary Module/Calculated Salary/Calculated_Salary";
import Advance from "../pages/Salary/Advance/Advance";
import SalarySlip from "../pages/Salary/SalarySlip";
import StaffIncentive from "../pages/Incentive/Incentive";
import Emi from "../pages/Salary/Emi/emi";

// Reports
import WasteReportCustomer from "../pages/Reports/wasteReportCustomer";
import StaffReport from "../pages/Reports/staffReport";
import DaywiseReport from "../pages/Reports/daywiseReport";
import PvtAgencyMonthlyReport from "../pages/Reports/PVTAgencyMonthlyReport/PvtAgencyMonthlyReport";
import MonthWiseReport from "../pages/Reports/MonthWiseReport/MonthWiseReport";
import WasteReportWard from "../pages/Reports/wasteReportWard/WasteReportWard";
import WasteReportDaily from "../pages/Reports/WasteReportDaily/WasteReportDaily";
import WardwiseReport from "../pages/Reports/wardwiseReport";
import GroupwiseReport from "../pages/Reports/groupwiseReport";
import Warehouse from "../pages/Manage/Warehouse";
import DaywiseWasteReport from "../pages/Reports/daywiseWasteReport";
import StaffwiseWasteReport from "../pages/Reports/staffwiseWasteReport";
import MonthwiseWasteReport from "../pages/Reports/monthwiseWasteReport";
import WardwiseWasteReport from "../pages/Reports/wardwiseWasteReport";
import GroupwiseWasteReport from "../pages/Reports/groupwiseWasteReport";
import StaffWalletReport from "../pages/Reports/staffWalletReport";
import CustomerPaymentReport from "../pages/Reports/customerpaymentReport";
import CustomerCollectStatusReport from "../pages/Reports/customercollectstatusReport";
import VisitPendingReport from "../pages/Reports/visitPendingReport";
import TotalSummaryReport from "../pages/Reports/TotalSummaryReport";
import NoVisitReport from "../pages/Reports/noVisitReport";

// security
import BlockedIP from "../pages/Security/Blocked IP/BlockedIP";
import UserBlock from "../pages/Security/User Block/UserBlock";
import UserActivityLog from "../pages/Security/User Activity Log/UserActivityLog";
import CronJobActivity from "../pages/Security/Cron Job Activity/CronJobActivity";

import WasteCollection from "../pages/offlineCollection/wasteCollection";
import ReceiptCollection from "../pages/offlineCollection/receiptCollection";

// Feedback
import Feedback from "../pages/Feedback/index";

// survey
import ManageSurvey from "../pages/Survey/Manage_survey";
import SurveyResult from "../pages/Survey/Survey Results";
import ViewSurvey from "../pages/Survey/View_survey";
// survey_old
import AddSurvey from "../pages/Survey_old/addSurvey";
import Survey from "../pages/Survey_old/surveyQuestions";

// creditnote
import AddCreditNote from "../pages/CreditNote";

// workrequest
import AddWorkRequest from "../pages/WorkRequest/addWorkRequest";
import Purchase from "../pages/WorkRequest/addWorkRequest/Purchase";

// food waste
import FoodWasteStatusLog from "../pages/foodWaste/statusLog";
import FoodWasteRequest from "../pages/foodWaste/requestDetails";
import AddRequestItem from "../pages/foodWaste/requestItem";
import WasteRequest from "../pages/Waste Request/index";
import NewWasteRequest from "../pages/Waste Request/index_new";

// TV
import TVDashboard from "../pages/TV/index";
import TVDashboardScreen1 from "../pages/TV/screen1";
import DashboardScreen2 from "../pages/TV/screen2";

// Dashboard new
import DashboardChart from "../pages/DashboardChart/DashboardChart";

// Bale creation
// import BaleCreation from "../pages/BaleCreation";
import BaleCreation from "../pages/BaleCreation"
// Driver Location
import DriverMap from "../pages/DriverMap";

// Collect Waste
import CollectWaste from "../pages/CollectWaste";

// Events
import Events from "../pages/Events";

// Warehouse
import WarehouseManage from "../pages/WarehouseManage";
import Intimation from "../pages/Intimation/Intimation";


const userRoutes = [
  // { path: "/dashboard", component: Dashboard },
  {
    path: "/dashboard",
    component: DashboardNew,
  },
  {
    path: "/user",
    component: User,
  },
  {
    path: "/staff",
    component: Staff,
  },
  {
    path: "/staff/:userId",
    component: StaffDetails,
  },
  {
    path: "/user/:userId",
    component: StaffDetails,
  },
  {
    path: "/profile/:userId",
    component: StaffDetails,
  },
  {
    path: "/company",
    component: Company,
  },
  {
    path: "/branch",
    component: Branch,
  },
  {
    path: "/privilage",
    component: Privilage,
  },
  {
    path: "/rules",
    component: Rules,
  },
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/rules_app",
    component: RulesApp,
  },
  {
    path: "/app_dashboard-old",
    component: Appdashboard,
  },
  {
    path: "/app-dashboard",
    component: AppmenuNew,
  },

  // Utility
  {
    path: "/pages-starter",
    component: PagesStarter,
  },
  {
    path: "/pages-timeline",
    component: PagesTimeline,
  },
  {
    path: "/pages-faqs",
    component: PagesFaqs,
  },
  {
    path: "/pages-pricing",
    component: PagesPricing,
  },

  // Ui
  {
    path: "/ui-alerts",
    component: UiAlert,
  },
  {
    path: "/ui-buttons",
    component: UiButtons,
  },
  {
    path: "/ui-cards",
    component: UiCards,
  },
  {
    path: "/ui-carousel",
    component: UiCarousel,
  },
  {
    path: "/ui-colors",
    component: UiColors,
  },
  {
    path: "/ui-dropdowns",
    component: UiDropdown,
  },
  {
    path: "/ui-general",
    component: UiGeneral,
  },
  {
    path: "/ui-grid",
    component: UiGrid,
  },
  {
    path: "/ui-images",
    component: UiImages,
  },
  {
    path: "/ui-lightbox",
    component: UiLightbox,
  },
  {
    path: "/ui-modals",
    component: UiModal,
  },
  {
    path: "/ui-progressbars",
    component: UiProgressbar,
  },
  {
    path: "/ui-sweet-alert",
    component: UiSweetAlert,
  },
  {
    path: "/ui-tabs-accordions",
    component: UiTabsAccordions,
  },
  {
    path: "/ui-typography",
    component: UiTypography,
  },
  {
    path: "/ui-video",
    component: UiVideo,
  },
  {
    path: "/ui-session-timeout",
    component: UiSessionTimeout,
  },
  {
    path: "/ui-rating",
    component: UiRating,
  },
  {
    path: "/ui-rangeslider",
    component: UiRangeSlider,
  },
  {
    path: "/ui-notifications",
    component: UiNotifications,
  },
  {
    path: "/ui-image-cropper",
    component: UiImageCropper,
  },

  // Forms
  {
    path: "/basic-elements",
    component: BasicElements,
  },
  {
    path: "/form-layouts",
    component: FormLayouts,
  },
  {
    path: "/form-advanced",
    component: FormAdvanced,
  },
  {
    path: "/form-editors",
    component: FormEditors,
  },
  {
    path: "/form-mask",
    component: FormMask,
  },
  {
    path: "/form-repeater",
    component: FormRepeater,
  },
  {
    path: "/form-uploads",
    component: FormUpload,
  },
  {
    path: "/form-wizard",
    component: FormWizard,
  },
  {
    path: "/form-validation",
    component: FormValidations,
  },
  {
    path: "/form-xeditable",
    component: FormXeditable,
  },

  // Tables
  {
    path: "/tables-basic",
    component: BasicTables,
  },
  {
    path: "/tables-datatable",
    component: DatatableTables,
  },
  {
    path: "/tables-responsive",
    component: ResponsiveTables,
  },
  {
    path: "/tables-editable",
    component: EditableTables,
  },

  // Charts
  {
    path: "/apex-charts",
    component: ChartApex,
  },
  {
    path: "/chartjs-charts",
    component: ChartjsChart,
  },
  {
    path: "/e-charts",
    component: EChart,
  },
  {
    path: "/sparkline-charts",
    component: SparklineChart,
  },
  {
    path: "/charts-knob",
    component: ChartsKnob,
  },

  // Icons
  {
    path: "/icons-unicons",
    component: IconUnicons,
  },
  {
    path: "/icons-boxicons",
    component: IconBoxicons,
  },
  {
    path: "/icons-dripicons",
    component: IconDripicons,
  },
  {
    path: "/icons-materialdesign",
    component: IconMaterialdesign,
  },
  {
    path: "/icons-fontawesome",
    component: IconFontawesome,
  },

  // Maps
  {
    path: "/maps-google",
    component: MapsGoogle,
  },
  {
    path: "/maps-vector",
    component: MapsVector,
  },
  {
    path: "/maps-leaflet",
    component: MapsLeaflet,
  },

  // //profile
  {
    path: "/profile",
    component: UserProfile,
  },

  // //manage
  {
    path: "/District",
    component: District,
  },
  {
    path: "/Localbody",
    component: Localbody,
  },
  {
    path: "/Ward",
    component: Ward,
  },
  {
    path: "/Group",
    component: Group,
  },
  {
    path: "/CustomerCategory",
    component: CustomerCategory,
  },
  {
    path: "/incentive",
    component: Incentive,
  },
  {
    path: "/holiday",
    component: Holiday,
  },
  {
    path: "/complaintcategory",
    component: ComplaintCategory,
  },
  {
    path: "/workrequest-category",
    component: WorkrequestCategory,
  },
  {
    path: "/foodwasteprocessing-item",
    component: FoodwasteProcessingItems,
  },
  {
    path: "/flat",
    component: Flat,
  },
  // Customers
  {
    path: "/Customer",
    component: AddCustomer,
  },
  {
    path: "/view-tariff",
    component: viewTariff,
  },
  {
    path: "/qr-code",
    component: QrCode,
  },
  {
    path: "/Customer/:customeId",
    component: ViewCustomer,
  },
  {
    path: "/assignGroup",
    component: AssignGroup,
  },
  {
    path: "/assignPackage",
    component: AssignPackage,
  },

  // accounts
  {
    path: "/account-type",
    component: AccountType,
  },
  {
    path: "/account-details",
    component: AccountDetails,
  },
  {
    path: "/accounts-chart",
    component: Chart,
  },
  {
    path: "/fund-transfer",
    component: FundTransfer,
  },
  {
    path: "/credit-debit-fund",
    component: CreditDebit,
  },
  {
    path: "/income-expense",
    component: IncomeExpenses,
  },
  {
    path: "/transaction-log",
    component: TransactionLog,
  },
  {
    path: "/transaction-log/:chartId",
    component: TransactionLog,
  },
  {
    path: "/profitAndLoss",
    component: ProfitAndLoss,
  },

  // Waste Collection
  {
    path: "/waste-collect",
    component: HelperCollection,
  },
  {
    path: "/waste-collect/helper",
    component: HelperDrop,
  },
  {
    path: "/waste-collect/driver-collect",
    component: DriverCollection,
  },
  {
    path: "/waste-collect/driver-drop",
    component: DriverDrop,
  },
  {
    path: "/waste-collect/waste-plots",
    component: WastePlot,
  },
  {
    path: "/waste-collect/waste-spots",
    component: GroupWasteSpot,
  },
  {
    path: "/HelperReport",
    component: HelperReport,
  },
  {
    path: "/DriverReport",
    component: DriverReport,
  },
  {
    path: "/WastePlotReport",
    component: WastePlotReport,
  },
  {
    path: "/WasteSpotReport",
    component: WasteSpotReport,
  },
  {
    path: "/wardwiseReport",
    component: WardwiseReport,
  },
  {
    path: "/groupwiseReport",
    component: GroupwiseReport,
  },
  {
    path: "/localbodyReport",
    component: LocalBodyReport,
  },

  // package
  {
    path: "/add-tariff",
    component: AddTariff,
  },
  {
    path: "/add-waste",
    component: AddWaste,
  },

  // Invoice
  {
    path: "/auto-invoice",
    component: ViewInvoice,
  },
  {
    path: "/view-invoice",
    component: AutoInvoice,
  },

  // Receipt
  {
    path: "/view-receipt",
    component: Receipt,
  },
  //
  {
    path: "/popup",
    component: ImagePopupView,
  },
  {
    path: "/notification",
    component: ManageNotification,
  },

  // chat
  {
    path: "/chat",
    component: Chat,
  },
  {
    path: "/slider",
    component: Slider,
  },
  // post
  {
    path: "/post",
    component: Post,
  },
  {
    path: "/wasterequest",
    component: WasteRequest,
  },
  {
    // old
    path: "/all-wasterequest",
    component: NewWasteRequest,
  },
  // new

  // Complaint
  {
    path: "/addComplaint",
    component: AddComplaint,
  },
  {
    path: "/complaints",
    component: Complaints,
  }, 
  {
    path: "/staffComplaint",
    component: StaffComplaint,
  },
  {
    path: "/complaintStatistic",
    component: ComplaintStatistic,
  },

  // customer nextvisit
  {
    path: "/nextvisit",
    component: CustomerNextvisit,
  },
  {
    path: "/verify-customer",
    component: VerifyCustomer,
  },

  // Attendance
  {
    path: "/CheckinCheckoutLog",
    component: CheckinCheckoutLog,
  },
  {
    path: "/attendance_report",
    component: AttendanceReport,
  },
  {
    path: "/attendance",
    component: AttendanceSummary,
  },

  // Salary
  {
    path: "/salary/calculateSalary",
    component: CalculateSalary,
  },
  {
    path: "/salary/slip",
    component: MonthlyPaidSalary,
  },
  {
    path: "/salary/Advance",
    component: Advance,
  },
  {
    path: "/salarySlip",
    component: SalarySlip,
  },
  {
    path: "/salary/incentive_list",
    component: StaffIncentive,
  },
  {
    path: "/emi",
    component: Emi,
  },

  // Reports
  {
    path: "/wasteReportCustomer",
    component: WasteReportCustomer,
  },
  {
    path: "/staffReport",
    component: StaffReport,
  },
  {
    path: "/daywiseReport",
    component: DaywiseReport,
  },
  {
    path: "/pvtagencymonthlyreport",
    component: PvtAgencyMonthlyReport,
  },
  {
    path: "/monthWiseReport",
    component: MonthWiseReport,
  },
  {
    path: "/wasteReportWard",
    component: WasteReportWard,
  },
  {
    path: "/wasteReportDaily",
    component: WasteReportDaily,
  },
  {
    path: "/daywiseWasteReport",
    component: DaywiseWasteReport,
  },
  {
    path: "/staffwiseWasteReport",
    component: StaffwiseWasteReport,
  },
  {
    path: "/monthwiseWasteReport",
    component: MonthwiseWasteReport,
  },
  {
    path: "/wardwiseWasteReport",
    component: WardwiseWasteReport,
  },
  {
    path: "/groupwiseWasteReport",
    component: GroupwiseWasteReport,
  },
  {
    path: "/staffWalletReport",
    component: StaffWalletReport,
  },
  {
    path: "/customerpaymentreport",
    component: CustomerPaymentReport,
  },
  {
    path: "/customercollectreport",
    component: CustomerCollectStatusReport,
  },
  {
    path: "/visitPendingReport",
    component: VisitPendingReport,
  },
  {
    path: "/totalSummaryReport",
    component: TotalSummaryReport,
  },
  {
    path: "/noVisitReport",
    component: NoVisitReport,
  },
  {
    path: "/blockedip",
    component: BlockedIP,
  },
  {
    path: "/userblock",
    component: UserBlock,
  },
  {
    path: "/UserActivityLog",
    component: UserActivityLog,
  },
  {
    path: "/Warehouse",
    component: Warehouse,
  },
  {
    path: "/intimation",
    component:Intimation ,
  },
  {
    path: "/CronJobActivity",
    component: CronJobActivity,
  },
  {
    path: "/wasteCollection",
    component: WasteCollection,
  },
  {
    path: "/receiptCollection",
    component: ReceiptCollection,
  },

  // survey
  {
    path: "/manage_survey",
    component: ManageSurvey,
  },
  {
    path: "/survey_result",
    component: SurveyResult,
  },
  {
    path: "/Surveyresult/:Cust_details",
    component: ViewSurvey,
  },

  // Feedbacks
  {
    path: "/customer-feedbacks",
    component: Feedback,
  },
  // Survey_old
  {
    path: "/add_survey",
    component: AddSurvey,
  },
  {
    path: "/surveylink",
    component: Survey,
  },

  // creditnote
  {
    path: "/addCreditNote",
    component: AddCreditNote,
  },

  // work request
  {
    path: "/WorkRequest",
    component: AddWorkRequest,
  },
  {
    path: "/purchase",
    component: Purchase,
  },

  // food waste
  {
    path: "/foodWasteStatusLog",
    component: FoodWasteStatusLog,
  },
  {
    path: "/foodWasteRequest",
    component: FoodWasteRequest,
  },
  {
    path: "/addRequestItem",
    component: AddRequestItem,
  },

  // TV Dashboard

  // Dashboarnew
  {
    path: "/dashboard1",
    component: DashboardChart,
  },

  // Bale creation
  {
    path: "/bale",
    component: BaleCreation,
  },

  // Driver Location
  {
    path: "/driver_location",
    component: DriverMap,
  },

  // Collect Waste
  {
    path: "/collect-waste",
    component: CollectWaste,
  },

  // Events
  {
    path: "/events",
    component: Events,
  },

  // Warehouse
  {
    path: "/work-request",
    component: WarehouseManage,
  },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const authRoutes = [
  {
    path: "/logout",
    component: Logout,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/forgot-password",
    component: ForgetPwd,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/verify-otp",
    component: VerifyOtp,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/pages-maintenance",
    component: PagesMaintenance,
  },
  {
    path: "/pages-comingsoon",
    component: PagesComingsoon,
  },
  {
    path: "/pages-404",
    component: Pages404,
  },
  {
    path: "/pages-500",
    component: Pages500,
  },
  {
    path: "/qr-code/:qrcodeId",
    component: ViewQrCode,
  },

  // Authentication Inner
  {
    path: "/pages-login",
    component: Login1,
  },
  {
    path: "/pages-register",
    component: Register1,
  },
  {
    path: "/page-recoverpw",
    component: Recoverpw,
  },
  {
    path: "/auth-lock-screen",
    component: LockScreen,
  },
  {
    path: "/tv_dashboard/:accessToken/:user_id",
    component: TVDashboard,
  },
  {
    path: "/tv_screen1/:accessToken/:user_id",
    component: TVDashboardScreen1,
  },
  {
    path: "/tv_screen2/:accessToken/:user_id",
    component: DashboardScreen2,
  },
];

export { userRoutes, authRoutes };
