import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import DialogActions from "@mui/material/DialogActions";
import { Modal } from "react-bootstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import {
  Row, Col, Card, CardBody, Button, Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./AddComplaint.scss";
import moment from "moment";
import { getLocalbody } from "../../../helpers/globalFunctions";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import "./AddComplaint.scss";
import { useReactToPrint } from "react-to-print";
import { PrintableComponent } from './PrintableComponent';

const DatatableTables = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const printRef = useRef()
  const [details, setDetails] = useState([]);
  const [master, setMaster] = useState({
    complaint_localbody: getLocalbody(),
  });
  const [localName, setLocalName] = useState([])
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [complaintCategoryOptions, setComplaintCategoryOptions] = useState([]);
  const [selectedComplaintCategory, setSelectedComplaintCategory] =
    useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerComplaintIdToBeUpdated, setCustomerComplaintIdToBeUpdated] =
    useState(null);
  const [customerComplaintIdToBeDeleted, setCustomerComplaintIdToBeDeleted] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedPopupStatus, setSelectedPopupStatus] = useState(null);
  const [complaintPopupTable, setComplaintPopupTable] = useState([]);
  const [popupData, setPopupData] = useState({});
  const [followUp, setFollowUp] = useState({});
  const [errors, setErrors] = useState({
    customerError: "",
    complaintError: "",
    wardError: "",
    priorityError: "",
  });
  const [popupError, setPopupError] = useState({
    popupStatusError: "",
    popupCommentError: "",
  });
  const [uploadProgress, setUploadProgress] = useState();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  useEffect(() => {
    handleTableData();
    fetch_all_wards(currentLocalbody);
    fetch_complaint_catagory();
    fetch_all_customers(currentLocalbody, "");
  }, [currentLocalbody]);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  function handleTableData() {
    axios
      .get(
        `${API_URL}customercomplaint/list?localbody_id=` + currentLocalbody,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {

        setLocalName(res.data.data.localbody)
        let result = res.data.data.data;

        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.time = moment(item.complaint_time, "HHmmss").format("hh:mm a");
            item.date = item.complaint_date;
            if (item.complaint_priority === 0) item.priority = "Low";
            else if (item.complaint_priority === 1) item.priority = "Medium";
            else item.priority = "High";

            if (item.complaint_activestatus === 0)
              item.status = <h6 style={{ color: "blue" }}>New</h6>;
            else if (item.complaint_activestatus === 1)
              item.status = <h6 style={{ color: "green" }}>Solved</h6>;
            else item.status = <h6 style={{ color: "red" }}>Unsolved</h6>;

            if (item?.complainant_lastname)
              item.complainant =
                item?.complainant_firstname + " " + item?.complainant_lastname;
            else item.complainant = item?.complainant_firstname;

            if (item.complaint_image && item.complaint_image.length > 0) {
              item.complaint_image &&
                item.complaint_image.map((img) => {
                  item.complaint_image = img;
                });
            } else item.complaint_image = "";

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => handleClickOpenModal(item)}
                ></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    preUpdateCustomerComplaint(item);
                    toTop();
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setCustomerComplaintIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );

            item.localbodyName = res.data.data.localbody;

          });
        setDetails(result);
      });
  }
  const preUpdateCustomerComplaint = (item) => {
    setCustomerComplaintIdToBeUpdated(item._id);
    axios
      .get(`${API_URL}customercomplaint/?complaint_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            let wardOptions = {
              label: item?.ward_name,
              value: item?.complaint_ward,
            };
            setSelectedWard(wardOptions);
            fetch_all_groups(wardOptions);
            let custOptions = {
              label: item?.against_name,
              value: item?.complaint_against,
            };
            setSelectedCustomer(custOptions);
            let complaintCatOptions = {
              label: item?.category_name,
              value: item?.complaint_category,
            };
            setSelectedComplaintCategory(complaintCatOptions);
            let priorityOption = {};
            if (item.complaint_priority === 0) {
              priorityOption = {
                label: "Low",
                value: 0,
              };
            } else if (item.complaint_priority === 1) {
              priorityOption = {
                label: "Medium",
                value: 1,
              };
            } else {
              priorityOption = {
                label: "High",
                value: 2,
              };
            }
            setSelectedPriority(priorityOption);
            let groupOptions = {
              label: item?.group_name,
              value: item?.complaint_group,
            };
            setSelectedGroup(groupOptions);
            if (item.complaint_image && item.complaint_image.length > 0) {
              item.complaint_image &&
                item.complaint_image.map((img) => {
                  item.complaint_image = img;
                });
            } else item.complaint_image = "";
          });
        setMaster(...result);
      });
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_complaint_catagory = () => {
    axios
      .get(`${API_URL}customercomplaint/categorylist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var complaint_category_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.category_name,
              value: el._id,
            };
          });

        setComplaintCategoryOptions([
          {
            options: complaint_category_data,
          },
        ]);
      });
  };
  const fetch_all_customers = (localbody, query) => {
    let data = {
      keyword: query,
      localbody_id: [localbody],
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const handleCustomerSearch = (query) => {
    let data = {
      keyword: query,
      localbody_id: currentLocalbody,
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };

  const handleValidSubmit = () => {
    if (
      selectedCustomer === null ||
      selectedComplaintCategory === null ||
      selectedWard === null ||
      selectedPriority === null
    ) {
      let errorVal = errors;
      if (selectedCustomer === null) {
        errorVal.customerError = "Please select customer";
      }
      if (selectedComplaintCategory === null) {
        errorVal.complaintError = "Please select complaint category";
      }
      if (selectedWard === null) {
        errorVal.wardError = "Please select ward";
      }
      // if (selectedGroup === null) {
      //   errorVal.groupError = "Please select group";
      // }
      if (selectedPriority === null) {
        errorVal.priorityError = "Please select priority";
      }
      setErrors({
        ...errorVal,
      });
    } else {
      if (customerComplaintIdToBeUpdated) {
        axios
          .put(
            `${API_URL}customercomplaint?complaint_id=` +
            customerComplaintIdToBeUpdated,
            master,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Customer complaint updated successfully");
              formRef.current.reset();
              setCustomerComplaintIdToBeUpdated(null);
              setSelectedCustomer(null);
              setSelectedPriority(null);
              setSelectedWard(null);
              setSelectedComplaintCategory(null);
              setMaster({
                complaint_localbody: getLocalbody(),
              });
              setSelectedGroup(null);
              handleTableData();
            } else {
              toastr.error("Failed to update customer complaint");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}api/staff/complaint`, master, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Customer complaint created successfully");
              formRef.current.reset();
              setSelectedComplaintCategory(null);
              setSelectedCustomer(null);
              setSelectedPriority(null);
              setSelectedWard(null);
              setSelectedComplaintCategory(null);
              setMaster({
                complaint_localbody: getLocalbody(),
              });
              handleTableData();
              setSelectedGroup(null);
            } else {
              toastr.error("Failed to create customer complaint");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };
  const handleClickOpenModal = (item) => {
    axios
      .get(
        `${API_URL}customercomplaint/followuplist?complaint_id=` + item._id,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            if (item?.complainant_lastname)
              item.complainant =
                item.complainant_firstname + " " + item.complainant_lastname;
            else item.complainant = item.complainant_firstname;
            item.time = moment(item.complaint_time, "hhmmss").format("hh:mm a");
            if (item.complaint_priority === 0) item.priority = "Low";
            else if (item.complaint_priority === 1) item.priority = "Medium";
            else item.priority = "High";

            if (item.complaint_activestatus === 0)
              item.status = <h6 style={{ color: "blue" }}>New</h6>;
            else if (item.complaint_activestatus === 1)
              item.status = <h6 style={{ color: "green" }}>Solved</h6>;
            else item.status = <h6 style={{ color: "red" }}>Unsolved</h6>;
          });
        setComplaintPopupTable(result);
      });
    setPopupError({
      popupCommentError: "",
      popupStatusError: "",
    });
    setFollowUp({
      followup_refno: item._id,
      followup_status: "",
      followup_comment: "",
    });
    setPopupData(item);
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  function createData(
    date,
    time,
    complaintId,
    customer,
    mobile,
    staff,
    priority,
    status
  ) {
    return {
      date,
      time,
      complaintId,
      customer,
      mobile,
      staff,
      priority,
      status,
    };
  }

  const rows = [
    createData(
      "2011/04/25",
      "13:45",
      "123",
      "Abhirag K",
      "989559454",
      "TPB3/Kuppam	",
      "High",
      "Pending",
      "Kamala"
    ),
  ];
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "Complaint ID",
        field: "complaint_id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Defendant",
        field: "defendant",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Mobile",
      //   field: "mobile",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Ward",
        field: "ward",
        sort: "asc",
        width: 150,
      },
      {
        label: "Priority",
        field: "priority",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "complainant",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Staff",
      //   field: "staff",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  const complaintTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "complaint_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Complainant",
        field: "complainant",
        sort: "asc",
        width: 150,
      },
      {
        label: "Complaint ID",
        field: "complaint_id",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Defendant",
      //   field: "defendant",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Category",
      //   field: "category",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Priority",
        field: "priority",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
    ],
    rows: complaintPopupTable,
  };

  // const [member2, setMember2] = React.useState("");
  // const [open2, setOpen2] = React.useState(false);
  // const handleChange2 = (selected) => {
  //   setMember2(selected);
  // };

  // const handleClose2 = () => {
  //   setOpen2(false);
  // };

  // const handleOpen2 = () => {
  //   setOpen2(true);
  // };

  // const Input = styled("input")({
  //   display: "none",
  // });
  // const [show, setShow] = React.useState(false);
  const fetch_all_groups = (selected) => {
    axios
      .get(
        `${API_URL}api/staff/groups?localbody_id=` +
        currentLocalbody +
        "&ward_id=" +
        selected.value,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data && res.data.success === true) {
          var group_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.group_name,
                value: el._id,
              };
            });
        } else {
          group_data = [];
        }

        setGroupOptions([
          {
            options: group_data,
          },
        ]);
      });
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setMaster({
          ...master,
          ["complaint_ward"]: selected.value,
          // ["complaint_against"]: "",
        });
        // setSelectedCustomer(null);
        axios
          .get(
            `${API_URL}customercomplaint/wardcustomer?ward_id=` +
            selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            var customer_data =
              res.data.data &&
              res.data.data.map((el) => {
                return {
                  label: el.cust_name,
                  value: el._id,
                };
              });

            setCustomerOptions([
              {
                options: customer_data,
              },
            ]);
          });
        setSelectedGroup(null);
        setErrors({
          ...errors,
          wardError: "",
        });
        fetch_all_groups(selected);
        break;
      case "priority":
        setSelectedPriority(selected);
        setMaster({
          ...master,
          ["complaint_priority"]: selected.value,
        });
        setErrors({
          ...errors,
          priorityError: "",
        });
        break;
      case "complaintCategory":
        setSelectedComplaintCategory(selected);
        setMaster({
          ...master,
          ["complaint_category"]: selected.value,
        });
        setErrors({
          ...errors,
          complaintError: "",
        });
        break;
      case "customer":
        setSelectedCustomer(selected);
        setMaster({
          ...master,
          ["complaint_against"]: selected.value,
        });
        setErrors({
          ...errors,
          customerError: "",
        });
        break;
      case "group":
        setSelectedGroup(selected);
        setMaster({
          ...master,
          ["complaint_group"]: selected.value,
        });
        break;
      case "status":
        setSelectedPopupStatus(selected);
        setFollowUp({
          ...followUp,
          followup_status: selected.value,
        });
        setPopupError({
          ...popupError,
          popupStatusError: "",
        });
        break;
      default:
        break;
    }
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
    setPopupError({
      ...popupError,
      popupCommentError: "",
    });
  };
  const handleSubmitPopup = () => {
    if (selectedPopupStatus === null || !followUp?.followup_comment) {
      let errorVal = popupError;
      if (selectedPopupStatus === null) {
        errorVal.popupStatusError = "Please select status";
      }
      if (!followUp?.followup_comment) {
        errorVal.popupCommentError = "Please add comments";
      }

      setPopupError({
        ...errorVal,
      });
    } else {
      axios
        .post(`${API_URL}customercomplaint/complaint_followup`, followUp, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Follow up submitted successfully");
            handleCloseModal();
            setSelectedPopupStatus(null);
            setPopupError({
              popupStatusError: "",
              popupCommentError: "",
            });
            handleTableData();
          } else {
            toastr.error("Failed to Submit Follow up");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const handlePopupInputChange = (e) => {
    setFollowUp({
      ...followUp,
      [e.target.name]: e.target.value,
    });
    // setPopupError({
    //   ...popupError,
    //   popupCommentError: "",
    // });
  };
  const deleteComplaintImage = () => {
    setMaster({
      ...master,
      [`complaint_image`]: "",
    });
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("complaint_image", e.target.files[0]);
    axios
      .post(`${API_URL}customercomplaint/complaintImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.file;
          let filename = "";
          result &&
            result.map((item) => {
              filename = item?.filename;
            });
          setMaster({
            ...master,
            [`complaint_image`]: filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  })


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}customercomplaint?complaint_id=` +
                customerComplaintIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Customer complaint deleted successfully");
                  if (
                    master &&
                    master?._id === customerComplaintIdToBeDeleted
                  ) {
                    formRef.current.reset();
                    setCustomerComplaintIdToBeUpdated(null);
                    setSelectedCustomer(null);
                    setSelectedPriority(null);
                    setSelectedWard(null);
                    setSelectedComplaintCategory(null);
                    setMaster({
                      complaint_localbody: getLocalbody(),
                    });
                    setSelectedGroup(null);
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete notification");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Customer Complaint" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Customer</Label>
                          <Select
                            name="complaint_against"
                            value={selectedCustomer}
                            onChange={(selected) => {
                              handleSelectChange(selected, "customer");
                            }}
                            options={customerOptions}
                            onInputChange={(input) =>
                              handleCustomerSearch(input)
                            }
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedCustomer === null
                              ? errors.customerError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="complaint_ward"
                            value={selectedWard}
                            onChange={(selected) => {
                              handleSelectChange(selected, "ward");
                            }}
                            options={wardOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedWard === null ? errors.wardError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Group</Label>
                          <Select
                            name="group_id"
                            value={selectedGroup}
                            onChange={(selected) => {
                              handleSelectChange(selected, "group");
                            }}
                            options={groupOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Complaint Category</Label>
                          <Select
                            name="complaint_category"
                            value={selectedComplaintCategory}
                            onChange={(selected) => {
                              handleSelectChange(selected, "complaintCategory");
                            }}
                            options={complaintCategoryOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedComplaintCategory === null
                              ? errors.complaintError
                              : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Priority</Label>
                          <Select
                            name="complaint_priority"
                            value={selectedPriority}
                            onChange={(selected) => {
                              handleSelectChange(selected, "priority");
                            }}
                            options={[
                              {
                                label: "Low",
                                value: 0,
                              },
                              {
                                label: "Medium",
                                value: 1,
                              },
                              {
                                label: "High",
                                value: 2,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedPriority === null
                              ? errors.priorityError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Comments</Label>
                          <AvField
                            name="complaint_comment"
                            placeholder="Comments"
                            type="text"
                            errorMessage="Add Comment"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            onChange={handleInputChange}
                            value={master?.complaint_comment}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Image</Label>
                          {master?.complaint_image ? (
                            <div div className="img-wraps">
                              {master.complaint_image &&
                                master.complaint_image.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${master.complaint_image}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/complaint_images/${master.complaint_image}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteComplaintImage}
                                style={{ width: "150px" }}
                              // type="reset"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="complaint_image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          {customerComplaintIdToBeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4">
                          <Button color="success" type="print" onClick={handlePrint}>Download</Button>
                        </div>
                      </Col>
                      {/* <label
                          htmlFor="icon-button-file"
                          style={{ marginTop: "2%" }}
                        >
                          <Input
                            accept="image/*"
                            id="icon-button-file"
                            type="file"
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <AccountCircleIcon />
                          </IconButton>
                        </label> */}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View Complaint</CardTitle> */}
                  {/* <hr /> */}
                  <MDBDataTable
                    id="customerComplaintId"
                    responsive
                    bordered
                    data={data}
                    disableRetreatAfterSorting={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div>
            <Modal
              show={openModal}
              toggle={handleCloseModal}
              size="lg"
              centered={true}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">Customer Complaint </h5>
                <button
                  type="button"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <AvForm
                className="needs-validation"
                onValidSubmit={(e, v) => {
                  handleSubmitPopup(e, v);
                }}
              >
                <div className="modal-body">
                  <h5>Basic Details</h5>
                  <table
                    id="product_available_day"
                    className="table table-bordered dataTable"
                  >
                    <tr>
                      <td style={{ padding: "10px", width: "184px" }}>
                        Name :
                      </td>
                      <td style={{ width: "200px", wordBreak: "break-all" }}>
                        {popupData?.defendant}
                      </td>
                      <td style={{ paddingLeft: "25px" }}>Category :</td>
                      <td style={{ textAlign: "left" }}>
                        {popupData?.category}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px" }}>Ward :</td>
                      <td>{popupData?.ward}</td>
                      <td style={{ paddingLeft: "25px" }}>Group :</td>
                      <td style={{ textAlign: "left" }}>{popupData?.group}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px" }}>Mobile :</td>
                      <td>{popupData?.mobile}</td>
                      <td></td>
                      <td style={{ textAlign: "left" }}>{ }</td>
                    </tr>
                    <tr hidden={!popupData?.complaint_image}>
                      <td style={{ padding: "10px", textAlign: "left" }}>
                        Image :
                      </td>
                      <td style={{ textAlign: "left" }}>
                        <img
                          style={{
                            width: "180px",
                            height: "180px",
                            paddingLeft: "0px",
                          }}
                          src={`${API_URL}uploads/complaint_images/${popupData?.complaint_image}`}
                          alt=""
                        ></img>
                      </td>
                      <td style={{ textAlign: "left" }}></td>
                      <td style={{ textAlign: "left" }}>{ }</td>
                    </tr>
                  </table>
                </div>

                <Row>
                  <Col className="col-12">
                    <AvForm>
                      <Row style={{ paddingLeft: "15px" }}>
                        <Col md="4">
                          <div className="mb-3">
                            <Label>Status</Label>
                            <Select
                              name="followup_status"
                              value={selectedPopupStatus}
                              onChange={(selected) => {
                                handleSelectChange(selected, "status");
                              }}
                              options={[
                                {
                                  label: "Solved",
                                  value: 1,
                                },
                                {
                                  label: "Unsolved",
                                  value: 2,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedPopupStatus === null
                                ? popupError.popupStatusError
                                : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="5">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Comments</Label>
                            <AvField
                              name="followup_comment"
                              placeholder="Comments"
                              type="textarea"
                              // errorMessage="Add Comment"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="validationCustom02"
                              onChange={handlePopupInputChange}
                              value={followUp?.followup_comment}
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {!followUp?.followup_comment
                                ? popupError.popupCommentError
                                : ""}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    <Row
                      style={{ paddingLeft: "15px", paddingRight: "15px" }}
                      className="mt-2"
                    >
                      <MDBDataTable
                        id="complaintPopupTableDataId"
                        responsive
                        bordered
                        data={complaintTableData}
                        paging={false}
                        searching={false}
                      />
                    </Row>
                  </Col>
                </Row>
                <DialogActions
                  style={{ paddingRight: "15px" }}
                  className="mb-2"
                >
                  <Button
                    type="submit"
                  // onClick={handleCloseModal}
                  >
                    Submit
                  </Button>
                </DialogActions>


              </AvForm>
              {/* <DialogContent> */}
              {/* <h5>Basic Details</h5>
                <br />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <h6>Name:</h6>
                  <p>Abhirag</p>
                  <h6>Mobile:</h6>
                  <p>989559454</p>
                  <h6>Email:</h6>
                  <p>abc@gmail.com</p>
                  <h6>Country:</h6>
                  <p>India</p>
                </Stack> */}
              {/* <Divider />
                <br /> */}
              {/* <Col md="3">
                  <div className="mb-3">
                    <FormControl sx={{ m: 1, width: 273 }}>
                      <Label htmlFor="validationCustom02">Status</Label>
                      <Select
                        name="status_id"
                        value={selectedComplaintCategory}
                        onChange={(selected) => {
                          handleSelectChange(selected, "status");
                        }}
                        options={[
                          {
                            label: "Solved",
                            value: 1,
                          },
                          {
                            label: "Unsolved",
                            value: 2,
                          },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </FormControl>
                  </div>
                </Col>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Add Comment"
                  type="email"
                  fullWidth
                  variant="standard"
                />
                <br />
                <br /> */}
              {/* <Row style={{ width: "fit-content" }}>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <CardTitle>View Complaint</CardTitle>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell align="left">Time</TableCell>
                                <TableCell align="left">
                                  Complaint ID&nbsp;
                                </TableCell>
                                <TableCell align="left">
                                  Customer&nbsp;
                                </TableCell>
                                <TableCell align="left">Mobile&nbsp;</TableCell>
                                <TableCell align="left">Staff&nbsp;</TableCell>
                                <TableCell align="left">
                                  Priority&nbsp;
                                </TableCell>
                                <TableCell align="left">Status&nbsp;</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => (
                                <TableRow
                                  key={row.date}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                  >
                                    {row.date}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                  >
                                    {row.time}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.complaintId}
                                  </TableCell>

                                  <TableCell align="left">
                                    {row.customer}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.mobile}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.staff}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.priority}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.status}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardBody>
                    </Card>
                  </Col>
                </Row> */}
              {/* </DialogContent> */}
              {/* <DialogActions>
                <Button onClick={handleCloseModal}>Reply</Button>
              </DialogActions> */}
            </Modal>
          </div>

          <div style={{ display: "none" }}>
            <PrintableComponent ref={printRef} data={details} name={localName} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
