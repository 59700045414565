import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  Progress,
  Input,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  getWasteItems,
  getWasteTypes,
  getWasteCategories,
  addWasteItem,
  updateWasteItem,
  deleteWasteItem,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getLocalbody, getCompany } from "../../../helpers/globalFunctions";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import "./addWaste.scss";
import { isArray } from "lodash";

const AddWaste = (props) => {
  const API_URL = process.env.REACT_APP_APIURL;
  
  const [uploadProgress, setUploadProgress] = useState();
  const [selectedType, setSelectedType] = useState(null);
  const [category, setCategory] = useState(null);
  const [wasteObject, setWasteObject] = useState({
    company_id: getCompany(),
    type: 1,
    localbody_id: getLocalbody(),
  });
  console.log(wasteObject, "wastee");

  const [selected, setSelected] = useState({
    type: { label: "Main", value: 1 },
  });
  

  const localbody = getLocalbody();
  const [itemsIdTobeUpdated, setItemsIdToBeUpdated] = useState(null);
  const [itemsIdToBeDeleted, setItemsIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [wasteItemsForTable, setWasteItemsForTable] = useState([]);
  const [itemname, setItemname] = useState("");
  const [bags, setBags] = useState("");
  const [weight, setWeight] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadedImage, setLoadedImage] = useState(null);
  const [popupView, setPopupView] = useState(false);
  const [allocateLocalbody, setAllocateLocalbody] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [localbodyAllocate, setLocalbodyAllocate] = useState([]);
  const [selectedAmountFor, setSelectedAmountFor] = useState(null);
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [commeAmount, setCommeAmount] = useState("");
  const [mainItemsOptions, setMainItemsOptions] = useState([]);


  const [monthOptions, setmonthOptions] = useState([
    { label: "Jan", value: "01" },
    { label: "Feb", value: "02" },
    { label: "Mar", value: "03" },
    { label: "Apr", value: "04" },
    { label: "May", value: "05" },
    { label: "Jun", value: "06" },
    { label: "Jul", value: "07" },
    { label: "Aug", value: "08" },
    { label: "Sep", value: "09" },
    { label: "Oct", value: "10" },
    { label: "Nov", value: "11" },
    { label: "Dec", value: "12" },
  ]);
  const [amountForOptions, setAmountForOptions] = useState([
    {
      label: "Bag",
      value: 0,
    },
    {
      label: "Weight",
      value: 1,
    },
  ]);
  const [selectedWastePaidStatus, setSelectedWastePaidStatus] = useState(null);
  const [popupLocalbodyData, setPopupLocalbodyData] = useState({});
  const [errors, setErrors] = useState({
    imageError: "",
    categoryError: "",
    wastetypeError: "",
    billingtypeError: "",
    amountforError: "",
  });
  const {
    wasteItems,
    wasteTypes,
    categories,
    addWasteItemResponse,
    updateWasteItemResponse,
    deleteWasteItemResponse,
    addingWasteItem,
    error,
  } = useSelector((state) => state.wasteItems);

  const dispatch = useDispatch();
  const formRef = useRef();
  // const handleSelectedMonth = (selected, index, item) => {
  //   let list = [...localbodyAllocate];
  //   var ids = [];
  //   selected.map((item) => {
  //     ids.push(item?.value)
  //   })
  //   list[index].waste_item_allocated_month = selected;
  //   setLocalbodyAllocate(list);
  //   var data = {
  //     item_id: item?.main_id,
  //     localbody_id: item?._id,
  //     month: selected
  //   }
  //   axios
  //     .post(`${API_URL}wasteItems/alocate-localbody/`, data, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         //dispatch(getWasteItems());
  //       }
  //     });
  // }

  const handleSelectedMonth = (selected, index, item) => {
    let list = [...localbodyAllocate];
    list[index].waste_item_allocated_month = selected;
    setLocalbodyAllocate(list);

    var data = {
      item_id: item?.main_id,
      localbody_id: item?._id,
      month: selected,
    };

    axios
      .post(`${API_URL}wasteItems/alocate-localbody/`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
        }
      });
  };
  const handleClickOpenForm = (item) => {
    let data = {
      id: item._id,
    };
    axios
      .post(`${API_URL}wasteItems/localbodies`, data, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        let result = response.data.data;
        if (response.data.status === 200) {
          result.forEach((element) => {
            element.main_id = data.id;
            element.waste_item_allocated_month =
              typeof element?.all_month !== "undefined" &&
              typeof element?.all_month[0] !== "undefined"
                ? element.all_month[0].month
                : [];
          });
          setLocalbodyAllocate(result);
          setPopupLocalbodyData(item);
          setAllocateLocalbody(true);
        } else {
          // toastr.error(response.data.message);
        }
      });
  };
  const handleShowPopup = (item) => {
    let imageData = item.waste_items_image[0] || {};
    item.image_name = imageData?.img;
    if (item.waste_items_amount_for === 1) item.amountFor = "Weight";
    else item.amountFor = "Bag";
    setPopupData(item);
    setPopupView(true);
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getPrivilagesOptions());
    dispatch(getCompaniesOptions());
    fetch_main_items(localbody)
    dispatch(getWasteItems());
    dispatch(getWasteTypes());
    dispatch(getWasteCategories());
  }, []);

  useEffect(() => {
    if (addWasteItemResponse.type === "success") {
      dispatch(getWasteItems());
      toastr.success(addWasteItemResponse.message);
      formRef.current.reset();
      setSelectedType(null);
      setCategory(null);
      setBags("");
      setWeight("");
      setAmount("");
      setItemname("");
      setLoadedImage(null);
      setSelectedAmountFor(null);
      setSelectedWastePaidStatus(null);
      //  setSelectedDistrict(null);
    } else if (addWasteItemResponse.type === "failure") {
      toastr.error(error.data.message, addWasteItemResponse.message);
    }
  }, [addWasteItemResponse]);

  useEffect(() => {
    if (deleteWasteItemResponse.type === "success") {
      dispatch(getWasteItems());
      toastr.success(deleteWasteItemResponse.message);
      setItemsIdToBeDeleted(null);
    } else if (deleteWasteItemResponse.type === "failure") {
      toastr.error(error.data.message, deleteWasteItemResponse.message);
    }
  }, [deleteWasteItemResponse]);

  useEffect(() => {
    if (updateWasteItemResponse.type === "success") {
      setShowModal(false);
      setItemsIdToBeUpdated(null);
      formRef.current.reset();
      setSelectedType(null);
      setCategory(null);
      setBags("");
      setWeight("");
      setAmount("");
      setItemname("");
      setLoadedImage(null);
      setSelectedAmountFor(null);
      setSelectedWastePaidStatus(null);
      dispatch(getWasteItems());
      toastr.success(updateWasteItemResponse.message);
    } else if (updateWasteItemResponse.type === "failure") {
      toastr.error(updateWasteItemResponse.message);
    }
  }, [updateWasteItemResponse]);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleReset = () => {
    setWasteObject({
      company_id: getCompany(),
      localbody_id: getLocalbody(),
      type: 1,
    });
    setSelected({ type: { label: "Main", value:1 } });
    formRef.current.reset();
    setSelectedType(null);
    setCategory(null);
    setBags("");
    setWeight("");
    setAmount("");
    setCommeAmount("");
    setItemname("");
    setLoadedImage(null);
    setSelectedAmountFor(null);
    setSelectedWastePaidStatus(null);
    setItemsIdToBeUpdated(null);
    dispatch(getWasteItems());
    setErrors({
      imageError: "",
      categoryError: "",
      wastetypeError: "",
      billingtypeError: "",
      amountforError: "",
    });
  };
  let preUpdateData = (item) => {
    setItemname(item.waste_items_name);
    setBags(item.waste_items_bag);
    setWeight(item.waste_items_weight);
    setAmount(item.waste_items_amount);
    setCommeAmount(item.waste_items_commercial_amount);
     item.type =item?.type
    item.name = item.waste_items_name;
    item.bags = item.waste_items_bag;
    item.weight = item.waste_items_weight;
    item.amount = item.waste_items_amount;
    item.waste_items_commercial_amount = item.waste_items_commercial_amount;
    item.file = item.waste_items_image;
    setSelected({type:{label:item.type === 1? "Main":"Sub",value:item.type}})
    if (item.waste_items_type) {
      let waste_items_type = {
        label: item.waste_items_type.waste_cat_name,
        value: item.waste_items_type._id,
      };
      item.Type = waste_items_type.value;
      handleSelectedType(waste_items_type);
    } 
    if (item.waste_item_cat) {
      let waste_item_cat = {
        label: item.waste_item_cat.waste_category_name,
        value: item.waste_item_cat._id,
      };
      item.category = waste_item_cat.value;
      handleChangeCategory(waste_item_cat);
    }
    if (item.waste_items_image) {
      //setSelectedImage(`${API_URL}uploads/waste_images/${item.waste_items_image[0].img}`)
      setSelectedImage(item.waste_items_image);
      setLoadedImage(
        `${API_URL}uploads/waste_images/${item.waste_items_image[0].img}`
      );
      // setSelected({
      //   mainItemId: { label: item?.mainItemId, value: item?.mainItemId },
      //   type:{label:item.type ,value:item.type}
      // });
    }

    if (item.waste_items_paid_status === 0) {
      setSelectedWastePaidStatus({
        label: "Paid",
        value: 0,
      });
      setAmountForOptions([
        {
          label: "Weight",
          value: 1,
        },
      ]);
    } else if (item.waste_items_paid_status === 1) {
      setSelectedWastePaidStatus({
        label: "Unpaid",
        value: 1,
      });
      setAmountForOptions([
        {
          label: "Bag",
          value: 0,
        },
        {
          label: "Weight",
          value: 1,
        },
      ]);
    }

    if (item.waste_items_amount_for === 0) {
      setSelectedAmountFor({
        label: "Bag",
        value: 0,
      });
    } else if (item.waste_items_amount_for === 1) {
      setSelectedAmountFor({
        label: "Weight",
        value: 1,
      });
    }
    setItemsIdToBeUpdated(item._id);
    setWasteObject(item);
  };

  useEffect(() => {
    let wasteItemsData = [];

    wasteItems.map((item, index) => {
      item.image = (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            height: "50px",
            with: "50px",
          }}
        >
          <img
            src={`${API_URL}uploads/waste_images/${item?.waste_items_image[0]?.img}`}
            height="50"
            width="50"
            alt=""
          ></img>
        </div>
      );
      // if (item.waste_items_status === 0) {
      //   item.status = (
      //     <div
      //       class="btn-group btn-group-sm"
      //       role="group"
      //       aria-label="Basic example"
      //     >
      //       <button
      //         class="btn btn-success"
      //         style={{ padding: "1px", minWidth: "50px" }}
      //       >
      //         Active
      //       </button>
      //     </div>
      //   );
      // } else if (item.waste_items_status === 2) {
      //   item.status = (
      //     <div
      //       class="btn-group btn-group-sm"
      //       role="group"
      //       aria-label="Basic example"
      //     >
      //       <button
      //         class="btn btn-danger"
      //         style={{ padding: "1px", minWidth: "50px" }}
      //       >
      //         InActive
      //       </button>
      //     </div>
      //   );
      // }
      // item.status = (
      //   <div style={{ display: "flex", justifyContent: "space-between" }}>
      //     <div
      //       className=""
      //       style={{
      //         cursor: "pointer",
      //         color: "black",
      //         fontSize: ".7em",
      //         padding: ".5rem",
      //         borderRadius: ".3rem",
      //         background: "#fb6262",
      //       }}
      //       //onClick={() => }
      //     >
      //       Active
      //     </div>
      //   </div>
      // );
      if (item.waste_items_paid_status === 0) {
        item.billing_type = "Paid";
      } else if (item.waste_items_paid_status === 1) {
        item.billing_type = "Unpaid";
      }
      item.status = (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            className="form-check form-switch mb-3"
            style={{ textAlign: "center" }}
          >
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitch2"
              checked={item.waste_items_activestatus}
              onChange={(e) => {
                toggleWasteActiveStatus(item._id);
                settoggleSwitch(!toggleSwitch);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      );

      item.id = index + 1;
      //   item.name1 = `${item.firstName} ${item.lastName}`;

      //   item.privilage1 = item.privilage && item.privilage.name;
      //   item.company1 = item.company && item.company.name;
      //   item.branch1 = item.branch && item.branch.name;
      if (item.waste_items_type)
        item.wastetype = item.waste_items_type.waste_cat_name;
      if (item.waste_item_cat)
        item.category = item.waste_item_cat.waste_category_name;
      if (item.waste_item_addedby)
        item.staff = item.waste_item_addedby.username;

      if (item.waste_item_cat) {
        item.categoryName = item.waste_item_cat.waste_category_name;
      }
      item.itemType =item.type === 1 ? "Main" : "Sub" 
      item.action = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="fas fa-eye"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.2em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              handleShowPopup(item);
            }}
          ></i>
          <i
            className="fas fa-code-branch"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.2em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              handleClickOpenForm(item);
            }}
          ></i>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.1em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              preUpdateData(item);
              toTop();
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: "1em", cursor: "pointer" }}
            onClick={() => {
              setItemsIdToBeDeleted(item._id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
          {/* <i
              className="uil-key-skeleton"
              style={{ fontSize: "1.3em", cursor: "pointer" }}
              onClick={() => {
                preUpdateUserPassword(item);
              }}
            ></i> */}

          {/* <i
              className="uil-edit-alt"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                   preUpdateData(item);
              }}
            ></i>
            <i
              className="uil-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                   preUpdateData(item);
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                  setItemsIdToBeDeleted(item._id);
                   setConfirmDeleteAlert(true);
              }}
            ></i> */}
        </div>
      );
      // item.image=item.waste_items_image[0].img
      wasteItemsData.push(item);
    });
    setWasteItemsForTable(wasteItemsData);
  }, [wasteItems]);

  const toggleWasteActiveStatus = (id) => {
    let data = {
      id: id,
    };
    axios
      .put(`${API_URL}wasteItems/status/` + id, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          dispatch(getWasteItems());
        }
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "itemType",
        sort: "asc",
        width: 400,
      },
      {
        label: "Item",
        field: "waste_items_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Category",
        field: "categoryName",
        width: 300,
      },

      {
        label: "Type    ",
        field: "wastetype",
        width: 300,
      },
      {
        label: "Bags  ",
        field: "waste_items_bag",
        width: 300,
      },
      {
        label: "Weight(Kg)",
        field: "waste_items_weight",
        width: 300,
      },
      {
        label: "Billing Type",
        field: "billing_type",
        width: 300,
      },
      {
        label: "Resi Amt",
        field: "waste_items_amount",
        width: 300,
      },
      {
        label: "Comm Amt",
        field: "waste_items_commercial_amount",
        width: 300,
      },
      {
        label: "Image ",
        field: "image",
        width: 300,
      },
      {
        label: "Staff ",
        field: "staff",
        width: 300,
      },
      {
        label: "Status",
        field: "status",
        width: 300,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: wasteItemsForTable,
  };
  //   let privilagesOptionsData =
  //     privilagesOptions &&
  //     privilagesOptions.data &&
  //     privilagesOptions.data.map((item) => {
  //       return {
  //         label: item.name,
  //         value: item._id,
  //       };
  //     });

  //   let companiesOptionsData =
  //     companiesOptions &&
  //     companiesOptions.data &&
  //     companiesOptions.data.map((item) => {
  //       return {
  //         label: item.name,
  //         value: item._id,
  //       };
  //     });

  //   let branchesOptionsData =
  //     branchesOptions &&
  //     branchesOptions.data &&
  //     branchesOptions.data.map((item) => {
  //       return {
  //         label: item.name,
  //         value: item._id,
  //       };
  //     });

  //   const privilagesOptionsGroup = [
  //     {
  //       options: privilagesOptionsData,
  //     },
  //   ];

  //   const companiesOptionsGroup = [
  //     {
  //       options: companiesOptionsData,
  //     },
  //   ];

  //   const branchesOptionsGroup = [
  //     {
  //       options: branchesOptionsData,
  //     },
  //   ];

  //   function handleChangeUser(e) {
  //     let name = e.target.name;
  //     let value = e.target.value;
  //     setUserObject({ ...userObject, [name]: value });
  //   }
  const handleValidSubmit = (event, values) => {
    const data = new FormData();
    data.append("id", itemsIdTobeUpdated);
    data.append("name", itemname);
    data.append("bags", 1);
    data.append("weight", weight);
    data.append("amount", amount);
    data.append("category", category?.value);
    data.append("Type", selectedType?.value);
    data.append("type", wasteObject?.type);
    if(wasteObject?.type === 2) data.append("mainItemId", wasteObject?.mainItemId);
    
    data.append("file", selectedImage);
    data.append(
      "waste_items_paid_status",
      wasteObject?.waste_items_paid_status
    );
    data.append("waste_items_amount_for", wasteObject?.waste_items_amount_for);
    data.append(
      "waste_items_commercial_amount",
      wasteObject?.waste_items_commercial_amount
    );

    // if (itemsIdTobeUpdated) {
    //   const update = new FormData();
    //   update.append("id", itemsIdTobeUpdated);
    //   if (wasteObject.Amount) {
    //     update.append("amount", wasteObject.Amount);
    //   }
    //   if (wasteObject.WasteItem) {
    //     update.append("name", wasteObject.WasteItem);
    //   }
    //   if (wasteObject.category._id) {
    //     update.append("category", wasteObject.category._id);
    //   }
    //   if (wasteObject.wasteType) {
    //     update.append("Type", wasteObject.wasteType._id);
    //   }
    //   if (wasteObject.Kg) {
    //     update.append("weight", wasteObject.Kg);
    //   }
    //   if (wasteObject.bags) {
    //     update.append("Bags", wasteObject.bags);
    //   }
    //   update.append("imageId", wasteObject.waste_items_image._id);
    //   update.append("id", itemsIdTobeUpdated);
    //   //update.append("body",JSON.stringify(wasteObject))
    //   update.append("file", wasteObject.image);
    //   dispatch(updateWasteItem(wasteObject));
    // } else {
    //   const data = new FormData();
    //   data.append("id", itemsIdTobeUpdated);
    //   data.append("name", itemname);
    //   data.append("bags", bags);
    //   data.append("weight", weight);
    //   data.append("amount", amount);
    //   data.append("category", category.value);
    //   data.append("Type", selectedType.value);
    //   data.append("file", selectedImage);
    //   data.append(
    //     "waste_items_paid_status",
    //     wasteObject.waste_items_paid_status
    //   );
    //   data.append("waste_items_amount_for", wasteObject.waste_items_amount_for);

    //   dispatch(addWasteItem(data));
    // }
    if (
      selectedImage === null ||
      category === null ||
      selectedType === null ||
      selectedWastePaidStatus === null ||
      selectedAmountFor === null
    ) {
      let errorVal = errors;
      if (selectedImage === null) {
        errorVal.imageError = "Please select image";
      }
      if (category === null) {
        errorVal.categoryError = "Please select category";
      }
      if (selectedType === null) {
        errorVal.wastetypeError = "Please select waste type";
      }
      if (selectedWastePaidStatus === null) {
        errorVal.billingtypeError = "Please select waste billing type";
      }
      if (selectedAmountFor === null) {
        errorVal.amountforError = "Please select amount for";
      }
      setErrors({
        ...errorVal,
      });
    } else {
      if (itemsIdTobeUpdated) {
        axios
          .put(`${API_URL}wasteItems/update`, data, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Waste item updated successfully");
              handleReset();
              // formRef.current.reset();
              // setSelectedType(null);
              // setCategory(null);
              // setBags("");
              // setWeight("");
              // setAmount("");
              // setCommeAmount("");
              // setItemname("");
              // setLoadedImage(null);
              // setSelectedAmountFor(null);
              // setSelectedWastePaidStatus(null);
              // setItemsIdToBeUpdated(null);
              // dispatch(getWasteItems());
              // setErrors({
              //   imageError: "",
              //   categoryError: "",
              //   wastetypeError: "",
              //   billingtypeError: "",
              //   amountforError: "",
              // });
              // setWasteObject({
              //   company_id: getCompany(),
              //   localbody_id: getLocalbody(),
              // });
            } else {
              toastr.error("Falied to update waste item");
              return;
            }
          });
      } else {
        axios
          .post(`${API_URL}wasteItems/upload`, data, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Waste item created successfully");
              dispatch(getWasteItems());

              // formRef.current.reset();
              // setSelectedType(null);
              // setCategory(null);
              // setBags("");
              // setWeight("");
              // setAmount("");
              // setCommeAmount("");
              // setItemname("");
              // setLoadedImage(null);
              // setSelectedAmountFor(null);
              // setSelectedWastePaidStatus(null);
              // setErrors({
              //   imageError: "",
              //   categoryError: "",
              //   wastetypeError: "",
              //   billingtypeError: "",
              //   amountforError: "",
              // });
              handleReset();
            } else {
              toastr.error("Falied to create waste item");
              return;
            }
          });
      }
      //axios.post("http://localhost:3099/wasteItems/upload",data).then(res=>console.log(res))
    }
  };

  // itemsIdTobeUpdated
  //   ? dispatch(updateWasteItem(data))
  //   : dispatch(addWasteItem(data));

  function handleSelectedType(value) {
    // let newValue = {
    //   name: value.label,
    //   _id: value.value,
    // };
    setSelectedType(value);
    setWasteObject({ ...wasteObject, ["Type"]: value.value });
    setErrors({
      ...errors,
      ["wastetypeError"]: "",
    });
  }
  function handleChangeCategory(value) {
    // let newValue = {
    //   name: value.label,
    //   _id: value.value,
    // };
    setCategory(value);
    setWasteObject({ ...wasteObject, ["category"]: value.value });
    setErrors({
      ...errors,
      ["categoryError"]: "",
    });
  }
 function fetch_main_items(localbody) {
    axios
      .get(`${API_URL}wasteItems/options/mainItem?localbodyId=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res)=>{
        console.log(res,"res");
        const data =res?.data?.data
        setMainItemsOptions(data)
        
      })
     
        
  }
  function handleChangeItemname(e) {
    let name = e.target.name;
    let value = e.target.value;

    setItemname(value);
    setWasteObject({ ...wasteObject, [name]: value });
  }
  function handleChangeBags(e) {
    let name = e.target.name;
    let value = e.target.value;

    setBags(value);
    setWasteObject({ ...wasteObject, [name]: value });
  }
  function handleChangeWeight(e) {
    let name = e.target.name;
    let value = e.target.value;

    setWeight(value);
    setWasteObject({ ...wasteObject, [name]: value });
  }
  function handleChangeAmount(e) {
    let name = e.target.name;
    let value = e.target.value;

    setAmount(value);
    setWasteObject({ ...wasteObject, [name]: value });
  }

  function handleChangeCommercial(e) {
    let name = e.target.name;
    let value = e.target.value;

    setCommeAmount(value);
    setWasteObject({ ...wasteObject, [name]: value });
  }

  function handleSelectedImage(e) {
    let name = e.target.name;
    let value = e.target.files[0];

    setLoadedImage(URL.createObjectURL(value));
    setSelectedImage(value);
    setErrors({
      ...errors,
      ["imageError"]: "",
    });
    setWasteObject({ ...wasteObject, [name]: value });
  }
  const deleteWasteIcon = () => {
    setSelectedImage(null);
    setLoadedImage(null);
    setWasteObject({ ...wasteObject, ["file"]: "" });
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "amountFor":
        setSelectedAmountFor(selected);
        setErrors({
          ...errors,
          ["amountforError"]: "",
        });
        setWasteObject({
          ...wasteObject,
          ["waste_items_amount_for"]: selected.value,
        });
        break;
      case "paidStatus":
        setSelectedWastePaidStatus(selected);
        setSelectedAmountFor(null);
        setErrors({
          ...errors,
          ["billingtypeError"]: "",
        });
        setWasteObject({
          ...wasteObject,
          ["waste_items_paid_status"]: selected.value,
        });
        if (selected.value === 0) {
          setAmountForOptions([
            {
              label: "Weight",
              value: 1,
            },
          ]);
        } else {
          setAmountForOptions([
            {
              label: "Bag",
              value: 0,
            },
            {
              label: "Weight",
              value: 1,
            },
          ]);
        }
        break;
      default:
        break;
    }
  };
  const handleSelect = (e, name) => {
    const value = e
      ? isArray(e)
        ? e.map((item) => item.value)
        : e.value
      : null;

    setWasteObject((pre) => ({ ...pre, [name]: value }));
    setSelected((pre) => ({ ...pre, [name]: e }));
  };
  const handleCheckboxChange = (e, index, item) => {
    let value = e.target.checked === true ? 1 : 0;
    let list = [...localbodyAllocate];
    list[index]["allocation_status"] = value;
    setLocalbodyAllocate(list);
    let localbodyArr = [];
    list.forEach((element) => {
      if (element.allocation_status === 1) {
        localbodyArr.push(element._id);
      }
    });
    let data = {
      id: item?.main_id,
      allocated_localbodies: localbodyArr,
    };
    axios
      .post(`${API_URL}wasteItems/alocate_localbodies`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {});
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}wasteItems` + "/" + itemsIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Waste item deleted successfully");
                  dispatch(getWasteItems());

                  if (wasteObject && wasteObject._id === itemsIdToBeDeleted) {
                    formRef.current.reset();
                    setItemsIdToBeUpdated(null);
                    setSelectedType(null);
                    setCategory(null);
                    setBags("");
                    setWeight("");
                    setAmount("");
                    setCommeAmount("");
                    setItemname("");
                    setLoadedImage(null);
                    setSelectedAmountFor(null);
                    setSelectedWastePaidStatus(null);
                    setWasteObject({
                      company_id: getCompany(),
                      localbody_id: getLocalbody(),
                    });
                  }
                } else {
                  toastr.error("Failed to delete waste item");
                  return;
                }
              });
            // dispatch(deleteWasteItem(itemsIdToBeDeleted));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal
        show={allocateLocalbody}
        toggle={() => setAllocateLocalbody(!allocateLocalbody)}
        size="lg"
        centered={true}
      >
        <div
          className="modal-content"
          // style={{ overflow: "auto", height: "408px" }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Allocate Localbodies</h5>
            <button
              type="button"
              onClick={() => {
                setAllocateLocalbody(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
            <div className="modal-body">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <td style={{ padding: "10px", width: "171px" }}>Item :</td>
                  <td style={{ textAlign: "left", width: "105.97px" }}>
                    {popupLocalbodyData?.waste_items_name}
                  </td>
                  <td>Category :</td>
                  <td style={{ textAlign: "left" }}>
                    {popupLocalbodyData?.categoryName}
                  </td>
                </tr>
                <tr className="mt-3">
                  <td style={{ padding: "10px" }} colSpan={4}>
                    <b>Localbodies</b>
                  </td>
                </tr>
              </table>
              {localbodyAllocate &&
                localbodyAllocate.map((item, index) => {
                  return (
                    // <table
                    //   id="product_available_day"
                    //   className="table table-bordered dataTable"
                    // >
                    //   <tr>
                    //     <th>{item.localbody_name} :</th>
                    //     <td>
                    //     <div
                    //       style={{
                    //         width: "100%",
                    //         display: "flex",
                    //         justifyContent: "left",
                    //       }}
                    //     >
                    //       <div
                    //         className="form-check form-switch mb-3"
                    //         style={{ textAlign: "center" }}
                    //       >
                    //       <input
                    //         name="allocation_status"
                    //         type="checkbox"
                    //         className="form-check-input"
                    //         id="customSwitch2"
                    //         checked={
                    //           item.allocation_status === 0 ? false : true
                    //         }
                    //         onChange={(e) =>
                    //           handleCheckboxChange(e, index, item)
                    //         }
                    //       />
                    //       </div>
                    //       </div>
                    //     </td>
                    //     <th></th>
                    //     <td style={{ textAlign: "left" }}></td>
                    //   </tr>
                    // </table>
                    <Row className="mt-2">
                      <Col md="4">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            {item.localbody_name}
                          </Label>
                          {/* <AvField
                            name="localbody_name"
                            placeholder=""
                            type="text"
                            value={item.localbody_name}
                            className="form-control"
                            id="validationCustom05"
                            readOnly
                          /> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="form-check form-switch mb-3"
                            style={{ textAlign: "center" }}
                          >
                            <input
                              style={{ cursor: "pointer" }}
                              name="allocation_status"
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitch2"
                              checked={
                                item.allocation_status === 0 ? false : true
                              }
                              onChange={(e) =>
                                handleCheckboxChange(e, index, item)
                              }
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <Select
                          name="month"
                          isMulti={true}
                          value={item?.waste_item_allocated_month}
                          onChange={(selected) => {
                            handleSelectedMonth(selected, index, item);
                          }}
                          options={monthOptions}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                    </Row>
                  );
                })}
            </div>
          </AvForm>
        </div>
      </Modal>
      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Waste Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Type :</td>
                <td>{popupData?.itemType}</td>
                {popupData?.itemType === "Sub" && (
<>
                <td style={{ textAlign: "left" }}>Main Item :</td>
                <td style={{ textAlign: "left" }}>{popupData?.mainItemId?.waste_items_name}</td>
</>
                )}
              </tr>
              <tr>
                {/* style={{ padding: "10px",width:"282px" }} */}
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Waste Item :
                </td>
                <td style={{ width: "6px" }}>{popupData?.waste_items_name}</td>
               
                <td style={{ textAlign: "left" }}>Category :</td>
                <td style={{ textAlign: "left" }}>{popupData?.categoryName}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Type :</td>
                <td>{popupData?.waste_items_type?.waste_cat_name}</td>
                <td style={{ textAlign: "left" }}>Bags :</td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.waste_items_bag}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Kg :</td>
                <td>{popupData?.waste_items_weight}</td>
                <td style={{ textAlign: "left" }}>Billing Type :</td>
                <td style={{ textAlign: "left" }}>{popupData?.billing_type}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Residential Amount :
                </td>
                <td>{popupData?.waste_items_amount}</td>
                <td style={{ textAlign: "left" }}>Commercial Amount :</td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.waste_items_commercial_amount}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Amount For :</td>
                <td>{popupData?.amountFor}</td>
                <td style={{ textAlign: "left" }}>Staff :</td>
                <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Image :</td>
                <td style={{ textAlign: "left" }}>
                  <img
                    style={{
                      width: "180px",
                      height: "180px",
                      paddingLeft: "0px",
                    }}
                    alt=""
                    src={`${API_URL}uploads/waste_images/${popupData?.image_name}`}
                  ></img>
                </td>
                <td style={{ textAlign: "left" }}>Status :</td>
                {popupData?.waste_items_activestatus === 1 ? (
                  <td
                    style={{
                      textAlign: "left",
                      color: "green",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    {"Active"}
                  </td>
                ) : popupData?.waste_items_activestatus === 0 ? (
                  <td
                    style={{
                      textAlign: "left",
                      color: "red",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    {"Inactive"}
                  </td>
                ) : null}
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Waste Items" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    enctype="multipart/form-data"
                  >
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            name="waste_category"
                            value={selected.type}
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "Main", value: 1 },
                              { label: "Sub", value: 2 },
                            ]}
                            defaultOptions={[{ label: "Main", value: 1 }]}
                            onChange={(e) => handleSelect(e, "type")}
                          />
                        </div>
                      </Col> 
                      {wasteObject.type === 1 ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Item Name (Main)
                            </Label>
                            <AvField
                              name="name"
                              type="text"
                              value={itemname}
                              placeholder="Item Name (Main)"
                              errorMessage="Enter Item Name (Main)"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              onChange={handleChangeItemname}
                            />
                          </div>
                        </Col>
                      ) : (
                        <>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Select Main Item</Label>
                              <Select
                                name="mainItemId"
                                value={selected.mainItemId}
                                classNamePrefix="select2-selection"
                                options={mainItemsOptions}
                                onChange={(e) => handleSelect(e, "mainItemId")}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom05">
                                Item Name (Sub)
                              </Label>
                              <AvField
                                name="name"
                                type="text"
                                placeholder="Item Name (Sub)"
                                errorMessage="Enter Item Name (Sub)"
                                className="form-control"
                                value={wasteObject?.subname}
                                validate={{ required: { value: true } }}
                                id="validationCustom05"
                                onChange={handleChangeItemname}
                              />
                            </div>
                          </Col>
                        </>
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Waste Category</Label>
                          <Select
                            name="waste_category"
                            value={category}
                            classNamePrefix="select2-selection"
                            options={categories.map((cat) => {
                              return {
                                label: cat.waste_category_name,
                                value: cat._id,
                                key: cat._id,
                              };
                            })}
                            onChange={handleChangeCategory}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {category === null ? errors.categoryError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Waste Type</Label>
                          <Select
                            name="waste_type"
                            classNamePrefix="select2-selection"
                            value={selectedType}
                            options={wasteTypes.map((types) => {
                              return {
                                label: types.waste_cat_name,
                                value: types._id,
                                key: types._id,
                              };
                            })}
                            onChange={handleSelectedType}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedType === null ? errors.wastetypeError : ""}
                          </p>
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">No.of Bags</Label>
                          <AvField
                            name="bags"
                            placeholder=""
                            type="number"
                            value={bags}
                            errorMessage="Enter No. of Bags"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            onChange={handleChangeBags}
                            min={0}
                          />
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Weight(Kg) per 1 bag
                          </Label>
                          <AvField
                            name="weight"
                            value={weight}
                            type="number"
                            errorMessage="Enter weight"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            onChange={handleChangeWeight}
                            min={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Waste Billing Type</Label>
                          <Select
                            name="waste_items_paid_status"
                            value={selectedWastePaidStatus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "paidStatus");
                            }}
                            classNamePrefix="select2-selection"
                            options={[
                              {
                                label: "Paid",
                                value: 0,
                              },
                              {
                                label: "Unpaid",
                                value: 1,
                              },
                            ]}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedWastePaidStatus === null
                              ? errors.billingtypeError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Residential Amount
                          </Label>
                          <AvField
                            name="amount"
                            placeholder="Residential Amount"
                            value={amount}
                            type="number"
                            errorMessage="Enter Residential Amount"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            onChange={handleChangeAmount}
                            min={0}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Commercial Amount
                          </Label>
                          <AvField
                            name="waste_items_commercial_amount"
                            placeholder="Commercial Amount"
                            value={commeAmount}
                            type="number"
                            errorMessage="Enter Commercial Amount"
                            validate={{ required: { value: true } }}
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleChangeCommercial}
                            min={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Amount For</Label>
                          <Select
                            name="waste_items_amount_for"
                            value={selectedAmountFor}
                            onChange={(selected) => {
                              handleSelectChange(selected, "amountFor");
                            }}
                            classNamePrefix="select2-selection"
                            options={amountForOptions}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedAmountFor === null
                              ? errors.amountforError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Image</Label>
                          {loadedImage !== null ? (
                            <div div className="img-wraps">
                              <img
                                alt=""
                                width="150px"
                                height="150px"
                                src={loadedImage}
                              />
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteWasteIcon}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <div>
                              <AvField
                                name="file"
                                type="file"
                                errorMessage="Select Image"
                                className="form-control"
                                id="myImageInput"
                                enctype="multipart/form-data"
                                onChange={handleSelectedImage}
                                // validate={{ required: { value: true } }}
                              />
                              <p
                                className="text-danger"
                                style={{ fontSize: "11px" }}
                              >
                                {selectedImage === null
                                  ? errors.imageError
                                  : ""}
                              </p>
                            </div>
                          )}
                          {/* {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )} */}
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Image</Label>
                          <AvField
                            name="file"
                            type="file"
                            errorMessage="Select Image"
                            className="form-control"
                            id="myImageInput"
                            enctype="multipart/form-data"
                            onChange={handleSelectedImage}
                            
                          />
                          <img
                            src={loadedImage}
                            style={{ height: "30px" }}
                          ></img>
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              {" "}
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col> */}
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "26px" }}>
                          {itemsIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              className="me-2"
                              disabled={addingWasteItem ? true : false}
                            >
                              {addingWasteItem ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              className="me-2"
                              type="submit"
                              disabled={addingWasteItem ? true : false}
                            >
                              {addingWasteItem ? "Adding" : "Submit"}
                            </Button>
                          )}
                          <Button
                            color="danger"
                            type="reset"
                            onClick={handleReset}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="addWasteId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(AddWaste));

AddWaste.propTypes = {
  error: PropTypes.any,
  wasteItems: PropTypes.array,
};
