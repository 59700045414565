import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table as table,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  apiError,
  getPackages,
  getCustomerType,
  getWasteItemsOptions,
} from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./addTariff.scss";
import { getLocalbody } from "../../../helpers/globalFunctions";

const AddTariff = (props) => {
  const [tariffObject, setTariffObject] = useState({
    localbody_name: getLocalbody(),
    cust_type:null
  });
  const [tariffIdTobeUpdated, setTariffIdToBeUpdated] = useState(null);
  const [tariffIdToBeDeleted, setTariffIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [packagesForTable, setpackagesForTable] = useState([]);
  const [tableData, setTableData] = useState({});
  const [form, setForm] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [values, setValues] = useState([{ wasteName: "", freeBag: null }]);
  const [wasteItemOptions, setWasteItemOptions] = useState([]);
  const { packageList } = useSelector(
    (state) => state.packageList
  );
  const [popupTableValues, setPopupTableValues] = useState([]);
  const { customerTypes } = useSelector((state) => state.customers);
  const [currentLocalbody, setCurrentLocalboy] = useState(getLocalbody());

  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    dispatch(getPackages(currentLocalbody));
    dispatch(getCustomerType());
    dispatch(getWasteItemsOptions());
    fetch_waste_item_list();
  }, [currentLocalbody]);

  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCategoryOptions([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);

  const fetch_waste_item_list = () => {
    axios
      .get(`${API_URL}addTariff/wastelist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        var waste_data =
          result &&
          result.map((el) => {
            return {
              label: el.waste_items_name,
              value: el._id,
            };
          });

        setWasteItemOptions([
          {
            options: waste_data,
          },
        ]);
      });
  };

  const addMore = () => {
    setValues([...values, { wasteName: "", freeBag: null }]);
  };

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    let valueArray = [];
    let freebagArray = [];

    list &&
      list.forEach((element) => {
        valueArray.push(element.wastename_id);
        freebagArray.push(element.freeBag);
      });
    setTariffObject({
      ...tariffObject,
      package_billing_id: valueArray,
      package_bags: freebagArray,
    });
    setValues(list);
  };

  let preUpdateTariff = (item) => {
    setTariffIdToBeUpdated(item._id);
    axios
      .get(`${API_URL}addTariff/listview?tariff_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data[0];
        // result &&
          // result.map((element, index) => {
            // let category = {};
            // if (item?.cust_type === 1) {
            //   category = {
            //     label: "Residence",
            //     value: 1,
            //   };
            // } else {
            //   category = {
            //     label: "Commercial",
            //     value: 0,
            //   };
            // }

            const category = []
            const values = []
            if(item?.cust_type?.length > 0){
              item?.cust_type.map((el) => {
                values.push(el._id)
                category.push({
                  label: el.customer_type_name,
                  value: el._id
                })
              })
            }
            console.log(values)
            setSelectedCategory(category);

            result.cust_type = values

            result.localbody_name = getLocalbody();

            var dataValue =
              result.package_billing_id &&
              result.package_billing_id.map((itm) => ({
                wasteName: itm.waste_items_name,
                wastename_id: itm._id,
              }));

            var packageBag =
              result.package_bags &&
              result.package_bags.map((el) => ({
                freeBag: el,
              }));

            let tableData = [];
            packageBag &&
              packageBag.forEach((bags, index) => {
                let val = {};
                val.wasteName = dataValue[index]?.wasteName;
                val.wastename_id = dataValue[index]?.wastename_id;
                if (bags.freeBag === 0) {
                  val.freeBag = String(bags.freeBag);
                } else val.freeBag = bags.freeBag;
                tableData.push(val);
              });
            setValues(tableData);
          // });
        setTariffObject(result);
      });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const reset = () => {
    formRef.current.reset();
    setTariffIdToBeUpdated(null);
    setSelectedCategory(null);
    setTariffObject({
      localbody_name: getLocalbody(),
    });
    setValues([{ wasteName: "", freeBag: null }]);
  };
  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}addTariff/listview?tariff_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((element, index) => {
            var dataValue =
              element.package_billing_id &&
              element.package_billing_id.map((itm) => ({
                wasteName: itm.waste_items_name,
                wastename_id: itm._id,
              }));

            var packageBag =
              element.package_bags &&
              element.package_bags.map((el) => ({
                freeBag: el,
              }));

            let tableValues = [];

            packageBag &&
              packageBag.forEach((bags, index) => {
                let val = {};
                val.id = index + 1;
                val.wasteName = dataValue[index]?.wasteName;
                val.freeBag = bags.freeBag;
                tableValues.push(val);
              });

            setPopupTableValues(tableValues);
          });
      });
    setTableData(item);
    setForm(true);
  };
  const handleCloseForm = () => {
    setForm(false);
  };
  useEffect(() => {
    let packageData = [];

    packageList?.map((item, index) => {
      item.id = index + 1;
      if (item.tariff_addedby) item.Staff = item.tariff_addedby.firstName;
      if (item.localbody_name)
        item.localbody = item.localbody_name.localbody_name;
      if (item.localbody_type)
        item.localbodyType = item.localbody_type.localbody_type_name;

      item.category = item?.cust_type?.map((el) => el.customer_type_name).join(",");

      // let category = item.cust_type || {};
      // item.category = category?.customer_type_name
      //   ? category.customer_type_name
      //   : "";
      // if (item?.cust_type === 1) item.category = "Residence";
      // else if (item?.cust_type === 0) item.category = "Commercial";
      // else item.category = "";

      if (item.package_billing_id && item.package_bags) {
        item.tariffItems = item.package_billing_id?.map((itm, index) => {
          return {
            itemName: itm.waste_items_name,
            number: item.package_bags[index],
          };
        });
      }

      if (item.package_bags) {
        const sum = item.package_bags.reduce(
          (partial_sum, a) => partial_sum + a,
          0
        );
        item.freebags = sum;
      }

      if (item.package_active_status === 0) {
        item.status = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="success"
              size="sm"
              onClick={() => {
                updatePackageStatus(item);
              }}
            >
              Active
            </Button>
          </div>
        );
      } else {
        item.status = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                updatePackageStatus(item);
              }}
            >
              Inactive
            </Button>
          </div>
        );
      }

      item.action = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="fas fa-eye"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.2em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              handleClickOpenForm(item);
            }}
          ></i>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.1em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              preUpdateTariff(item);
              toTop();
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: "1em", cursor: "pointer" }}
            onClick={() => {
              setTariffIdToBeDeleted(item._id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      packageData.push(item);
    });
    setpackagesForTable(packageData);
  }, [packageList]);

  const updatePackageStatus = (item) => {
    axios
      .put(`${API_URL}addTariff/updatestatus?tariff_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          dispatch(getPackages(currentLocalbody));
        } else {
          toastr.error(res.data.message);
          return;
        }
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Package	",
        field: "package_name",
        width: 300,
      },
      {
        label: "Category",
        field: "category",
        width: 300,
      },
      {
        label: "Reg.Fee	",
        field: "package_reg_fee",
        width: 300,
      },
      {
        label: "Basic Fee",
        field: "package_basic_fee",
        width: 300,
      },
      {
        label: "Package Validity (Days)",
        field: "package_validity",
        width: 300,
      },
      {
        label: "Visit/Month",
        field: "package_visit_month",
        width: 300,
      },
      // {
      //   label: "Free Bags",
      //   field: "freebags",
      //   width: 300,
      // },
      {
        label: "Staff",
        field: "Staff",
        width: 300,
      },
      {
        label: "Status",
        field: "status",
        width: 300,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: packagesForTable,
  };

  const handleValidSubmit = () => {
    let flag = true;
    let validFreeBags = true;
    values &&
      values.forEach((element) => {
        if (element.wasteName === "") {
          flag = false;
        } else if (element.freeBag === null || element.freeBag === "") {
          validFreeBags = false;
        }
      });
    if (flag === false || validFreeBags === false) {
      if (flag === false) {
        toastr.error("Waste item required");
        return;
      } else {
        toastr.error("Free bags required");
        return;
      }
    } else {
      if (tariffIdTobeUpdated) {
        axios
          .put(
            `${API_URL}addTariff?tariff_id=` + tariffIdTobeUpdated,
            tariffObject,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Tariff updated successfully");
              dispatch(getPackages(currentLocalbody));
              formRef.current.reset();
              setTariffIdToBeUpdated(null);
              setSelectedCategory(null);
              setValues([{ wasteName: "", freeBag: null }]);
              setTariffObject({
                localbody_name: getLocalbody(),
              });
            } else {
              toastr.error("Failed to create tariff");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}addTariff`, tariffObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Tariff created successfully");
              dispatch(getPackages(currentLocalbody));
              formRef.current.reset();
              setSelectedCategory(null);
              setValues([{ wasteName: "", freeBag: null }]);
              setTariffObject({
                localbody_name: getLocalbody(),
              });
            } else {
              toastr.error("Failed to create tariff");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  function handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setTariffObject({ ...tariffObject, [name]: value });
  }

  function handleChangeWasteItem(selected, index) {
    const list = [...values];

    list[index]["wasteName"] = selected.label;
    list[index]["wastename_id"] = selected.value;
    setValues(list);
    let valueArray = [];
    list &&
      list.forEach((element) => {
        valueArray.push(element.wastename_id);
      });

    setTariffObject({ ...tariffObject, package_billing_id: valueArray });
  }
  function handleTableInputchange(e, index) {
    const { name, value } = e.target;
    const list = [...values];

    list[index][name] = value;

    setValues(list);
    let valueArray = [];
    list &&
      list.forEach((element) => {
        valueArray.push(element.freeBag);
      });
    setTariffObject({ ...tariffObject, package_bags: valueArray });
  }

  const handleSelectedChange = (selected) => {
    setSelectedCategory(selected);
    setTariffObject({
      ...tariffObject,
      ["cust_type"]: selected.map((item) => item.value),
    });
  };
  const popupTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Item Name",
        field: "wasteName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Free Bags",
        field: "freeBag",
        sort: "asc",
        width: 150,
      },
    ],
    rows: popupTableValues,
  };
  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              // dispatch(deletePackage(tariffIdToBeDeleted));
              axios
                .delete(`${API_URL}addTariff` + "/" + tariffIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.success === true) {
                    toastr.success("Tariff deleted successfully");
                    dispatch(getPackages(currentLocalbody));
                    if (
                      tariffObject &&
                      tariffObject._id === tariffIdToBeDeleted
                    ) {
                      formRef.current.reset();
                      setTariffIdToBeUpdated(null);
                      setSelectedCategory(null);
                      setValues([{ wasteName: "", freeBag: null }]);
                      setTariffObject({
                        localbody_name: getLocalbody(),
                      });
                    }
                  } else {
                    toastr.error(res.data.message, "Failed to delete tariff");
                    return;
                  }
                })
                .catch((err) => {
                  toastr.error(err.response.data.message);
                  return;
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <Modal
          show={form}
          toggle={() => setForm(!form)}
          size="lg"
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Tariff Details</h5>
            <button
              type="button"
              onClick={() => {
                setForm(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
            <div className="modal-body">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <td style={{ padding: "10px", width: "282px" }}>
                    Package Name :
                  </td>
                  <td>{tableData?.package_name}</td>
                  <td>Category :</td>
                  <td style={{ textAlign: "left" }}>{tableData?.category}</td>
                </tr>
                <tr>
                  <td style={{ padding: "10px", width: "282px" }}>
                    Registration Fee :
                  </td>
                  <td>{tableData?.package_reg_fee}</td>
                  <td>Basic Fee :</td>
                  <td style={{ textAlign: "left" }}>
                    {tableData?.package_basic_fee}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: "10px", width: "282px" }}>
                    Package Validity in Days :
                  </td>
                  <td>{tableData?.package_validity}</td>
                  <td>Visit/Month :</td>
                  <td style={{ textAlign: "left" }}>
                    {tableData?.package_visit_month}
                  </td>
                </tr>
                <tr>
                  {/* <td style={{ padding: "10px", width: "282px" }}>
                    Free Bags :
                  </td> */}
                  <td style={{ padding: "10px", width: "282px" }}>Staff :</td>
                  <td>{tableData?.Staff}</td>
                  <td></td>
                  <td style={{ textAlign: "left" }}>{ }</td>
                </tr>
              </table>
              <Row className="mt-3">
                <MDBDataTable
                  id="tariffPopupTableId"
                  responsive
                  bordered
                  data={popupTableData}
                  searching={false}
                  info={false}
                  paging={false}
                  disableRetreatAfterSorting={true}
                //entries={10}
                />
              </Row>
              {/* <Row className="mt-3">
                <div className="py-2">
                  <div className="table-responsive mb-3">
                    <table
                      className="table table-bordered"
                      style={{ border: "1px solid #ced4da" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "70px" }}>#</th>
                          <th>Item Name</th>
                          <th>Free Bags</th>
                        </tr>
                      </thead>
                      <tbody>
                        {popupTableValues &&
                          popupTableValues.map((val, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val.wasteName}</td>
                                <td>{val.freeBag}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Row> */}
            </div>
          </AvForm>
        </Modal>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Add Tariff" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Package Name
                            </Label>
                            <AvField
                              name="package_name"
                              placeholder="Package Name"
                              type="text"
                              value={tariffObject?.package_name}
                              errorMessage="Enter Package Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Category</Label>
                            <Select
                              name="cust_type"
                              isMulti
                              value={selectedCategory}
                              onChange={(selected) => {
                                handleSelectedChange(selected);
                              }}
                              classNamePrefix="select2-selection"
                              options={categoryOptions}
                            // options={[
                            //   {
                            //     label: "Commercial",
                            //     value: 0,
                            //   },
                            //   {
                            //     label: "Residence",
                            //     value: 1,
                            //   },
                            // ]}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Registration Fee
                            </Label>
                            <AvField
                              name="package_reg_fee"
                              placeholder="Registration Fee"
                              type="number"
                              min="0"
                              value={tariffObject?.package_reg_fee}
                              errorMessage="Enter Registration Fee"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Basic Fee
                            </Label>
                            <AvField
                              name="package_basic_fee"
                              placeholder="Basic Fee"
                              type="number"
                              min="0"
                              value={tariffObject?.package_basic_fee}
                              errorMessage="Enter Basic Fee"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Package Validity in Days
                            </Label>
                            <AvField
                              name="package_validity"
                              placeholder="Package Validity in Days"
                              type="number"
                              min="0"
                              value={tariffObject?.package_validity}
                              errorMessage="Enter Package Validity in Days"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Visit/Month
                            </Label>
                            <AvField
                              name="package_visit_month"
                              placeholder="Visit/Month"
                              type="number"
                              min="0"
                              value={tariffObject?.package_visit_month}
                              errorMessage="Enter Visit/Month"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Table
                          style={{ textAlign: "center" }}
                          id="waste_id"
                          className="table table-bordered dataTable"
                        >
                          <TableHead>
                            <TableRow style={{ textAlign: "center" }}>
                              <TableCell
                                style={{ width: "10px", textAlign: "center" }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                style={{ width: "280px", textAlign: "center" }}
                              >
                                Waste Item
                              </TableCell>
                              <TableCell
                                style={{ width: "123px", textAlign: "center" }}
                              >
                                Free Bags
                              </TableCell>
                              {values.length !== 1 ? (
                                <TableCell
                                  style={{ width: "10px", textAlign: "center" }}
                                >
                                  Action
                                </TableCell>
                              ) : null}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values &&
                              values.map((item, index) => (
                                <TableRow>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ textAlign: "center" }}
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell>
                                    <Select
                                      onChange={(selected) =>
                                        handleChangeWasteItem(selected, index)
                                      }
                                      name="wasteName"
                                      classNamePrefix="select2-selection"
                                      options={wasteItemOptions}
                                      value={{
                                        label: item.wasteName,
                                        value: item.wastename_id,
                                      }}
                                      id={`validationCustom03${index}`}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <AvField
                                      name="freeBag"
                                      value={item?.freeBag ? item.freeBag : ""}
                                      type="number"
                                      min={0}
                                      errorMessage=""
                                      className="form-control"
                                      id="validationCustom05"
                                      onChange={(e) =>
                                        handleTableInputchange(e, index)
                                      }
                                    />
                                  </TableCell>
                                  {values.length !== 1 && (
                                    <i
                                      className="uil uil-times"
                                      style={{
                                        fontSize: "20px",
                                        cursor: "pointer",
                                        paddingLeft: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                      onClick={() => handleRemove(index)}
                                    ></i>
                                  )}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Row>
                      <span
                        onClick={() => addMore()}
                        style={{ width: "190px", cursor: "pointer" }}
                      >
                        {" "}
                        + Add New Row{" "}
                      </span>
                      <br />
                      <Row>
                        <Col md="2" >
                          <div style={{ display: "flex" }} >
                            <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>


                              {tariffIdTobeUpdated ? (
                                <Button
                                  color="primary"
                                  type="submit"
                                //disabled={addingPackage ? true : false}
                                >
                                  {"Update"}
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  type="submit"
                                //disabled={addingPackage ? true : false}
                                >
                                  {"Submit"}
                                </Button>
                              )}
                            </div>
                            <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>

                              <Button
                                color="danger"
                                type="reset"
                                onClick={() => reset()}
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      id="addTariffTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
      {/* <div>
        <Dialog open={form} onClose={handleCloseForm}>
          <DialogTitle style={{ textAlign: "center" }}>
            <CloseIcon
              onClick={handleCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableBody>
                    {tableData.map((row) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          LocalbodyType:
                        </TableCell>
                        <TableCell align="left">{row.localbodyType}</TableCell>
                        <TableCell align="left">LocalbodyName:</TableCell>
                        <TableCell align="left">{row.localbody}</TableCell>
                      </TableRow>
                    ))}
                    {tableData.map((row) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Package Name:
                        </TableCell>
                        <TableCell align="left">{row.package_name}</TableCell>
                        <TableCell align="left">Category:</TableCell>
                        <TableCell align="left">{row.category}</TableCell>
                      </TableRow>
                    ))}
                    {tableData.map((row) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Registration Fee:
                        </TableCell>
                        <TableCell align="left">
                          {row.package_reg_fee}/-
                        </TableCell>
                        <TableCell align="left">Basic Fee:</TableCell>
                        <TableCell align="left">
                          {row.package_basic_fee}/-
                        </TableCell>
                      </TableRow>
                    ))}
                    {tableData.map((row) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Package Validity In Days:
                        </TableCell>
                        <TableCell align="left">
                          {row.package_validity} days
                        </TableCell>
                        <TableCell align="left">Visit/Month:</TableCell>
                        <TableCell align="left">
                          {row.package_visit_month}
                        </TableCell>
                      </TableRow>
                    ))}
                    {tableData.map((row) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Free Bags:
                        </TableCell>
                        <TableCell align="left">{row.freebags} </TableCell>
                        <TableCell align="left">Staff:</TableCell>
                        <TableCell align="left">{row.Staff}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <DialogTitle style={{ textAlign: "center" }}>
                  Tariff Item Detailes
                </DialogTitle>
                <Table>
                  <TableHead>
                    {tableData.map((row) => (
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Item Name
                        </TableCell>

                        <TableCell align="left" style={{ fontWeight: "bold" }}>
                          Free Bags
                        </TableCell>

                       
                      </TableRow>
                    ))}

                    {tableData.map((row) => (
                      <TableRow>
                        {row.tariffItems?.map((data) => {
                          return (
                            <TableRow>
                              <TableCell align="left">
                                {data.itemName}
                              </TableCell>

                              <TableCell align="left">{data.number}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody>
                    {tableData.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell align="left"></TableCell>

                        <TableCell align="left">{}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(AddTariff));

//AddTari.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
