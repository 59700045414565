import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import Select from "react-select";

import axios from "axios";
import toastr from "toastr";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Modal } from "react-bootstrap";
//import validator from "validator";

import SweetAlert from "react-bootstrap-sweetalert";
import * as XLSX from 'xlsx';
import {
  getDate,
  getFirstday,
  getLocalbody,
} from "../../../helpers/globalFunctions";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./managesurvey.scss";

import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";
import { ResetTvOutlined } from "@mui/icons-material";


const Survey = () => {

  const [masterObject, setmasterObject] = useState({
    localbody: getLocalbody(),
    question: [{ multiple_choice: [{}, {}, {}, {}] }]
  });
  const [surveyIdTobeUpdated, setsurveyIdToBeUpdated] = useState(null);
  const [surveyIdToBeDeleted, setsurveyIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [listForTable, setlistForTable] = useState([]);
  const [subcategValue, setSubcategValue] = useState("");
  const [checklist, setChecklist] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [flow, setFlow] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [showMcq, setShowMcq] = useState(false);
  const [showModal, setModal] = useState(false);
  const [reportData, setReport] = useState([]);
  const [values, setValues] = useState([
    {},
  ]);
  const [values1, setValues1] = useState([]);
  //     {name:"mcq1"},
  //     {name:"mcq2"},
  //     {name:"mcq3"},
  //     {name:"mcq4"},
  //   ]);

  const [errors, setErrors] = useState({
    checklistValid: "",
  });

  const {
    districts,
    addingSubcategory,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();



  useEffect(() => {
    handleTableData();
    fetch_checklist_id();
  }, []);

  function fetch_checklist_id() {
    axios
      .get(`${API_URL}taskchecklist/option`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ChecklistID =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.checklist_name,
              value: el._id,
            };
          });
        setChecklist([{ options: ChecklistID }]);
      });
  }
  function getSurvayReport(id) {
    axios
      .get(`${API_URL}survey/report?id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setReport(res.data.data);
        const formattedData = res.data.data.map((record) => {
          const { name, mobile, house, ward_name, address, questions } = record;
          const formattedRecord = { name, mobile, house, ward_name, address };
          Object.entries(questions).forEach(([question, answer]) => {
            formattedRecord[question] = answer;
          });
          return formattedRecord;
        });

        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
        XLSX.writeFile(workbook, 'report.xlsx');
      });
  }
  function handleTableData() {
    var url = `${API_URL}survey/list?localbody=` + currentLocalbody;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;


        let itemData = [];

        result.map((item, index) => {

          item.id = index + 1;
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="mdi mdi-file-excel"
                title="Report"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                  color: "green"
                }}
                onClick={() => {
                  getSurvayReport(item?._id);
                  // setModal(true);
                  // toTop();
                  // preUpdateSurvey(item);
                }}
              ></i>
              <i
                className="uil-edit-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  toTop();
                  preUpdateSurvey(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setsurveyIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );


          item.start_date1 = moment(item.start_date).format("DD-MM-YYYY");
          item.end_date1 = moment(item.end_date).format("DD-MM-YYYY");
          if (item?.updated_date) {
            item.date = moment(item.updated_date).format("DD-MM-YYYY");
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY");
          }

          if (item?.updatedby) {
            let values = item?.updatedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName;
          }

          itemData.push(item);
        });

        setlistForTable(itemData);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Survey Name",
        field: "survey_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Start Date",
        field: "start_date1",
        sort: "asc",
        width: 150,
      },

      {
        label: "End Date",
        field: "end_date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: listForTable,
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let preUpdateSurvey = (item) => {
    setsurveyIdToBeUpdated(item._id);
    axios
      .get(`${API_URL}survey/view?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let tableData = [];
        result.question &&
          result.question.map((cont, index) => {
            let val = {};
            val.qstn = cont?.qstn;

            // cont.multiple_choice &&
            //   cont.multiple_choice.map((item, index) => {

            //     if (index == 0) {

            //       val.mcq1 = item;
            //     }
            //     if (index == 1) {

            //       val.mcq2 = item;
            //     }
            //     if (index == 2) {

            //       val.mcq3 = item;

            //     }
            //     if (index == 0) {

            //       val.mcq4 = item;
            //     }
            //   })

            let typeOption = {};
            if (cont?.type == 0) {

              typeOption = {
                label: "Fill In The Blank",
                value: 0,
              };
              val.type_name = typeOption?.label
              val.type = cont?.type
              val.show = false;
              // setShowMcq(false)
            }
            if (cont?.type == 1) {

              typeOption = {
                label: "Yes/No",
                value: 1,
              };
              val.type_name = typeOption?.label
              val.type = cont?.type
              val.show = false;
              //  setShowMcq(false)
            }
            if (cont?.type == 2) {

              typeOption = {
                label: "MCQ",
                value: 2,
              };
              val.type_name = typeOption?.label
              val.type = cont?.type
              val.show = true

              //setShowMcq(true)
            }

            val.multiple_choice = cont.multiple_choice;
            tableData.push(val);
          })
        setValues(tableData);

      });
    setmasterObject(item);
  };

  function handleChangeItem(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObject({ ...masterObject, [name]: value });
  }

  function handleTableInputchange(e, index) {
    const list = [...values];
    list[index]["qstn"] = e.target.value;
    setValues(list);
    setmasterObject({ ...masterObject, question: list });
  }

  function handleTableInputchange1(e, index, indx = null) {
    const list = [...values];
    //const list1 = [...values1];
    const list1 = list[index]["multiple_choice"] ? list[index]["multiple_choice"] : [];
    // if (e.target.name == "mcq1") {

    //   list1[0] = e.target.value;
    // }
    // if (e.target.name == "mcq2") {

    //   list1[1] = e.target.value;
    // }
    // if (e.target.name == "mcq3") {

    //   list1[2] = e.target.value;
    // }
    // if (e.target.name == "mcq4") {

    //   list1[3] = e.target.value;
    // }
    if (indx == null || list1.length == 0) {
      list1.push(e.target.value)
    }
    else {
      list1[indx] = e.target.value
    }

    setValues1(list1);

    list[index]["multiple_choice"] = list1;
    setmasterObject({ ...masterObject, question: list });
  }

  function handleChangeSelectItem(selected, index) {

    const list = [...values];
    list[index]["type"] = selected.value;
    list[index]["type_name"] = selected.label;
    if (selected.value === 2) {
      list[index]["show"] = true
      //setShowMcq(true)
    }
    if (selected.value === 0) {
      list[index]["show"] = false
      //setShowMcq(false)
    }
    if (selected.value === 1) {
      list[index]["show"] = false
      //setShowMcq(false)
    }
    // if(list.includes({"type":2})){setShowMcq(true)}
    setValues(list);
    setmasterObject({ ...masterObject, question: list });
  }
  const handleValidSubmit = () => {
    // let total_weight =masterObject.item_checklist.reduce((n, {weightage}) => n + Number(weightage), 0)
    // let checklist = true;
    // let weightage = true;
    // values &&
    //   values.forEach((element) => {
    //     if (element.checklist_id === "") {
    //         checklist = false;
    //     } else if (element.weightage === "") {
    //         weightage = false;
    //     }
    //   });

    //   if( checklist === false|| weightage === false){

    //     if (checklist === false) {
    //       toastr.error("Checklist required");
    //       return;
    //     } else if(weightage === false){
    //       toastr.error("Weightage required");
    //       return;
    //      }else{
    //       toastr.error("Total Weightage must be minimum 50%");
    //       return;
    //      }

    // }
    if (

      !masterObject?.start_date || !masterObject?.end_date
    ) {
      let errorVal = errors;

      if (!masterObject?.start_date)
        errorVal.dateError = "Please select survey start date";
      if (!masterObject?.end_date)
        errorVal.dateErrors = "Please select survey end date";

      setErrors({
        ...errorVal,
      });
    }

    else {

      if (surveyIdTobeUpdated) {

        axios
          .put(`${API_URL}survey/update`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Survey updated successfully");
              handleTableData();
              formRef.current.reset();
              setValues([
                {

                },
              ]);
              setmasterObject({});
              setsurveyIdToBeUpdated(null);
            } else {
              toastr.error("Failed to update Survey");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}survey/`, masterObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Survey added succesfully");
              handleTableData();
              formRef.current.reset();
              setValues([
                {

                },
              ]);
              setmasterObject({
                localbody: '624e7a160e1dde69cb0dbb46'
              });

            } else {
              toastr.error("Failed to add Survey");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };
  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setmasterObject({
      ...masterObject,
      "question": list
    });
    setValues(list);
    setmasterObject({ ...masterObject, question: list });
  };
  const handleRemoveChoice = (indx, index) => {
    const list = [...values];
    var list1 = list[index]['multiple_choice'];
    list1.splice(indx, 1)
    list[index]['multiple_choice'] = list1;
    setValues(list);
    setmasterObject({ ...masterObject, question: list });

  }

  const addMore = () => {
    setValues([
      ...values,
      {
      },
    ]);

  };
  const addMoreOption = (index) => {
    var list = [...values];
    var list1 = list[index]["multiple_choice"] ? list[index]["multiple_choice"] : [];
    list1.push("");
    list[index]["multiple_choice"] = list1;
    setValues(list);
  }
  const handleDate = (e) => {
    setmasterObject({
      ...masterObject,
      [e.target.name]: e.target.value,
    });
  };
  const reset = () => {
    handleTableData();
    formRef.current.reset();
    setValues([{

    }]);
    setmasterObject({});
    //   setShowMcq([{ 

    //   }]);
  };
  const customStyles = {
    lineHeight: "1.5",
  };
  const customStyles1 = {
    paddingTop: "10px"
  }

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(
                  `${API_URL}survey` + "/" + surveyIdToBeDeleted,
                  {
                    headers: {
                      "x-access-token": accessToken,
                    },
                  }
                )
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Survey deleted successfully");
                    if (
                      masterObject &&
                      masterObject._id === surveyIdToBeDeleted
                    ) {
                      formRef.current.reset();
                      setmasterObject({});
                      setsurveyIdToBeUpdated(null);
                      setValues([{

                      }]);
                      //setSubcategValue("");
                      // setShowMcq([{ 

                      // }]);

                    }
                    handleTableData();
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Survey"
                    );
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <Modal
          show={showModal}
          toggle={() => setModal(!showModal)}
          size="fullscreen"
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Report</h5>
            <button
              type="button"
              onClick={() => {
                setModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '8px' }}>Name</th>
                  <th style={{ border: '1px solid black', padding: '8px' }}>House No</th>
                  <th style={{ border: '1px solid black', padding: '8px' }}>Mobile</th>
                  <th style={{ border: '1px solid black', padding: '8px' }}>Ward</th>
                  <th style={{ border: '1px solid black', padding: '8px' }}>Address</th>
                  {reportData.length > 0 &&
                    Object.keys(reportData[0].questions).map((question) => (
                      <th
                        key={question}
                        style={{ border: '1px solid black', padding: '8px' }}
                      >
                        {question}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {reportData.map((record) => (
                  <tr key={record.mobile}>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{record.name}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{record.house}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{record.mobile}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{record.ward_name}</td>
                    <td style={{ border: '1px solid black', padding: '8px' }}>{record.address1}</td>
                    {Object.values(record.questions).map((answer, index) => (
                      <td
                        key={index}
                        style={{ border: '1px solid black', padding: '8px' }}
                      >
                        {answer}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </Modal>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage Survey" />

            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Survey Name
                            </Label>
                            <AvField
                              style={customStyles}
                              name="survey_name"
                              placeholder="Survey Name"
                              type="text"
                              errorMessage="Enter Survey Name"
                              validate={{ required: { value: true } }}
                              className="form-control"
                              id="validationCustom01"
                              value={masterObject?.survey_name}
                              onChange={handleChangeItem}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Start Date
                            </Label>
                            <input
                              style={customStyles}
                              className="form-control"
                              type="date"
                              id="start_date"
                              name="start_date"
                              required={true}
                              value={masterObject?.start_date}
                              onChange={handleDate}

                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {!masterObject?.start_date ? errors.dateError : ""}
                            </p>
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              End Date
                            </Label>
                            <input
                              style={customStyles}
                              className="form-control"
                              type="date"
                              id="end_date"
                              name="end_date"
                              required={true}
                              value={masterObject?.end_date}
                              onChange={handleDate}

                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {!masterObject?.end_date ? errors.dateErrors : ""}
                            </p>
                          </div>
                        </Col>

                        {/* Table Fields */}
                        {/* <Row> */}
                        <Col className="col-12">
                          <CardBody style={{ padding: "0px 0px 2px 0px" }}>
                            {/* Add Person start*/}
                            <Row>
                              <Col xl="12">
                                <Table
                                  style={{ textAlign: "center" }}
                                  id="waste_id"
                                  className="table table-bordered dataTable"
                                >
                                  <TableHead>
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                          fontWeight: "500",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                        }}
                                      >
                                        No.
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                          fontWeight: "500",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                        }}
                                      >
                                        Question
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "500",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                        }}
                                      >
                                        Type
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "500",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                        }}
                                      >
                                        {values.some(item => item.type === 2) === true ? (

                                          <>
                                            MCQ Options
                                          </>

                                        ) : null}
                                      </TableCell>
                                      {/* <TableCell
                                        style={{
                                          width: "100px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          fontWeight: "500",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color:"#495057",
                                        }}
                                      >
                                        Priority
                                      </TableCell> */}
                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "500",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                          fontSize: "12.819px",
                                          fontFamily: "IBM Plex Sans, sans-serif",
                                          color: "#495057",
                                        }}
                                      >
                                        {values.length !== 1 ? (

                                          <>Action</>

                                        ) : null}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {values &&
                                      values.map((item, index) => (
                                        <TableRow>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              textAlign: "center", fontSize: "12.819px",
                                              fontFamily: "IBM Plex Sans, sans-serif",
                                              color: "#495057", fontWeight: "500"
                                            }}
                                          >
                                            {index + 1}
                                          </TableCell>

                                          <TableCell>
                                            <AvField
                                              name="qstn"
                                              value={item.qstn}
                                              type="text"
                                              //   validate={{
                                              //     required: { value: true },
                                              //   }}
                                              //   errorMessage="Enter Weightage"
                                              className="form-control"
                                              id="validationCustom05"
                                              onChange={(e) =>
                                                handleTableInputchange(e, index)
                                              }
                                            />
                                          </TableCell>

                                          <TableCell>
                                            <Select
                                              onChange={(selected) =>
                                                handleChangeSelectItem(
                                                  selected,
                                                  index
                                                )
                                              }
                                              name="type"
                                              //value={item.type}
                                              options={[
                                                {
                                                  label: "Fill In The Blank",
                                                  value: 0,
                                                },
                                                {
                                                  label: "Yes/No",
                                                  value: 1,
                                                },
                                                {
                                                  label: "MCQ",
                                                  value: 2,
                                                },
                                              ]}
                                              classNamePrefix="select2-selection"
                                              value={{
                                                label: item.type_name,
                                                value: item.type,
                                              }}
                                            //   value={{
                                            //     label: item.checklist_name,
                                            //     value: item.checklist_id,
                                            //   }}
                                            //   id={`validationCustom04${index}`}
                                            />
                                            {/* <span
                                              className="text-danger"
                                              style={{ fontSize: "11px" }}
                                           >
                                          
                                          {selectedChecklist === null
                                          ? errors.checklistValid
                                          : ""}
                                         
                                            </span> */}

                                          </TableCell>
                                          <TableCell>
                                            {item?.show === true ? (
                                              <>
                                                {
                                                  item?.multiple_choice?.length > 0 ? (
                                                    <>
                                                      {
                                                        item?.multiple_choice?.map((val, indx) => {
                                                          return (
                                                            <>
                                                              <Row>
                                                                <Col md="10">
                                                                  <AvField
                                                                    name="mcq1"
                                                                    value={val}
                                                                    type="text"
                                                                    //   validate={{
                                                                    //     required: { value: true },
                                                                    //   }}
                                                                    //   errorMessage="Enter Weightage"
                                                                    className="form-control"
                                                                    id="validationCustom05"
                                                                    onChange={(e) =>
                                                                      handleTableInputchange1(e, index, indx)
                                                                    }
                                                                  />
                                                                </Col>
                                                                <Col md="2">
                                                                  {
                                                                    indx != 0 ? (
                                                                      <i
                                                                        className="uil uil-times"
                                                                        style={{
                                                                          fontSize: "20px",
                                                                          cursor: "pointer",
                                                                          paddingLeft: "5px",
                                                                          display: "flex",
                                                                          justifyContent: "center",
                                                                        }}
                                                                        onClick={() =>
                                                                          handleRemoveChoice(indx, index)
                                                                        }
                                                                      ></i>
                                                                    ) : null
                                                                  }
                                                                </Col>
                                                              </Row>


                                                              <p></p>
                                                            </>
                                                          )
                                                        })
                                                      }
                                                    </>

                                                  ) : (
                                                    <>
                                                      <AvField
                                                        name="mcq1"
                                                        value={""}
                                                        type="text"
                                                        //   validate={{
                                                        //     required: { value: true },
                                                        //   }}
                                                        //   errorMessage="Enter Weightage"
                                                        className="form-control"
                                                        id="validationCustom05"
                                                        onChange={(e) =>
                                                          handleTableInputchange1(e, index)
                                                        }
                                                      />
                                                      <p></p>
                                                    </>
                                                  )
                                                }
                                                <span
                                                  onClick={() => addMoreOption(index)}
                                                  style={{
                                                    width: "190px", cursor: "pointer", fontSize: "12.819px",
                                                    fontFamily: "IBM Plex Sans, sans-serif",
                                                    color: "#495057", fontWeight: "500"
                                                  }}
                                                >
                                                  {" "}
                                                  + Add New {" "}
                                                </span>

                                              </>


                                            ) : null}
                                          </TableCell>
                                          <TableCell>
                                            {values.length !== 1 && (
                                              <i
                                                className="uil uil-times"
                                                style={{
                                                  fontSize: "20px",
                                                  cursor: "pointer",
                                                  paddingLeft: "5px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                                onClick={() =>
                                                  handleRemove(index)
                                                }
                                              ></i>

                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </Col>
                            </Row>
                            <span
                              onClick={() => addMore()}
                              style={{
                                width: "190px", cursor: "pointer", fontSize: "12.819px",
                                fontFamily: "IBM Plex Sans, sans-serif",
                                color: "#495057", fontWeight: "500"
                              }}
                            >
                              {" "}
                              + Add New {" "}
                            </span>
                            <br />
                            {/*Add Person End*/}
                          </CardBody>
                        </Col>
                        {/* </Row> */}
                        {/* Table Fields */}
                        <Col md="3">
                          <div className="mb-2" style={{ paddingTop: "25px" }}>
                            {surveyIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingSubcategory ? true : false}
                              >
                                {addingSubcategory ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                disabled={addingSubcategory ? true : false}
                              >
                                {addingSubcategory ? "Adding" : "Submit"}
                              </Button>
                            )}
                            <Button
                              color="danger"
                              type="reset"
                              onClick={() =>
                                reset()}
                            >
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col >
            </Row >
            <Row>
              <Col className="col-12">
                <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody>
                    <MDBDataTable
                      id="TaskFlowTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div >
        </div >
      </React.Fragment >

      {/* pay modal end */}
    </>
  );
};

export default Survey;