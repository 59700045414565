import React, { useEffect, useRef, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import "./style.scss";
import { getLocalbody } from "../../../helpers/globalFunctions";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import { isArray } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import toastr from "toastr";
import { getCustomerType } from "../../../store/actions";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
const VerifyCustomer = () => {
  const localbody = getLocalbody();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [tableData, setTabledata] = useState([]);
  const [selected, setSelected] = useState({});

  const [uploadProgress, setUploadProgress] = useState();
  // options
  const [customerType, setCustomerType] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);
  const [packageSearchOptions, setPackageSearchOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [master, setMaster] = useState({
    // cust_verification_status: 1,
    localbody_name: getLocalbody(),
  });
  console.log(master, "master");
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    handleTableData(localbody);
    fetch_all_designation();
    fetch_all_district(localbody);
    fetch_all_wards(localbody);
    fetch_all_packages(localbody);
  }, []);

  const { customerTypes } = useSelector((state) => state.customers);

  function fetch_all_district(localbody) {
    axios
      .get(`${API_URL}api/staff/districts?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          var district_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.district_name,
                value: el._id,
              };
            });
        } else {
          district_data = [];
        }

        setDistrictOptions([
          {
            options: district_data,
          },
        ]);
      });
  }
  function fetch_all_packages(localbody_id, type = null, from = "") {
    axios
      .get(
        `${API_URL}api/staff/packages?cust_type=` +
          type +
          `&localbody_id=` +
          localbody_id,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          var package_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.package_name,
                value: el._id,
                status: el.visit_status,
              };
            });
        } else package_data = [];
        if (from == "")
          setPackageOptions([
            {
              options: package_data,
            },
          ]);
        else {
          setPackageSearchOptions([
            {
              options: package_data,
            },
          ]);
        }
      });
  }
  async function fetch_all_wards(localbody) {
    return await axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);

        return ward_data;
      });
  }
  const fetch_all_group = (selected) => {
    axios
      .get(
        `${API_URL}api/staff/groups?localbody_id=` +
          localbody +
          "&ward_id=" +
          selected,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data && res.data.success === true) {
          var group_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.group_name,
                value: el._id,
              };
            });
        } else {
          group_data = [];
        }

        setGroupOptions([
          {
            options: group_data,
          },
        ]);
      });
  };
  useEffect(() => {
    if (master?.ward) {
      fetch_all_group(master.ward);
    }
  }, [master?.ward]);
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("cust_image", e.target.files[0]);
    axios
      .post(`${API_URL}customers/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setMaster({
            ...master,
            [`cust_image`]: response.data.file.filename,
          });
          // toastr.success("Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
    // setErrors({
    //   ...errors,
    //   ["imageError"]: "",
    // });
  };
  const deleteCustomerImage = () => {
    setMaster({
      ...master,
      [`cust_image`]: "",
    });
  };
  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
          type: el.type,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);
  function fetch_all_designation() {
    axios
      .get(`${API_URL}designations/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var designation_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.desig_name,
              value: el._id,
            };
          });

        setDesignationOptions([
          {
            options: designation_data,
          },
        ]);
      });
  }

  const handleUpdate = async (item) => {
    toTop();
    // const wards = await fetch_all_wards(localbody);
    setMaster((prev) => ({
      ...prev,
      ...item,
      ward: item?.ward?._id,
    }));
    setSelected({
      ward: {
        label: item?.ward?.name,
        value: item?.ward?._id,
      },
    });
  };
  const handleTableData = (localbody) => {
    axios
      .get(`${API_URL}customers/registered/list?localbodyId=${localbody}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        const data = res?.data?.data;
        data.map((item, index) => {
          item.id = index + 1;
          item.date = moment(item?.cust_date).format("DD-MM-YYYY");
          item.wardname = item?.ward?.name;
          item.action = (
            <>
              <Button
                color="primary"
                onClick={() => handleUpdate(item)}
                style={{
                  marginRight: "5px",
                  fontSize: "smaller",
                  padding: "3px 16px",
                }}
              >
                Verify
              </Button>
              {/* <Button
                color="danger"
                size="sm"
                style={{ fontSize: "smaller", padding: "3px 16px" }}
              >
                Reject
              </Button> */}
            </>
          );
          return item;
        });
        setTabledata(data);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reg No.",
        field: "cust_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "cust_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Phone",
        field: "cust_phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ward",
        field: "wardname",
        sort: "asc",
        width: 100,
      },
      {
        label: "Building No",
        field: "cust_house_num",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Address",
      //   field: "Address",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: tableData,
  };

  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelectChange = (e, name) => {
    const value = e
      ? isArray(e)
        ? e.map((item) => item.value)
        : e.value
      : null;
    if (name === "ward") {
      fetch_all_group(value);
    }
    if (name === "category") {
      dispatch(getCustomerType(value));
    }
    setSelected((pre) => ({ ...pre, [name]: e }));
    setMaster((pre) => ({ ...pre, [name]: value }));
  };

  const handleValidSubmit = () => {
    axios
      .put(`${API_URL}customers/verify_v2`, master, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("Customer updated successfully");
          handleReset();
          handleTableData();
        } else {
          toastr.error(res.data.message, "Failed to update customer");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const handleReset = () => {
    formRef.current.reset();
    setMaster({
      localbody_name: getLocalbody(),
    });
    setSelected({
      ward: "",
      category: "",
      cust_type: "",
      cust_designation: "",
      cust_package_id: "",
      cust_group_id: "",
      cust_billing_type: "",
    });
  };
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Verify Customer" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  ref={formRef}
                  className="needs-validation"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Category Type</Label>
                        <Select
                          name="category"
                          value={selected?.category}
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "Residential", value: 1 },
                            { label: "Commercial", value: 2 },
                          ]}
                          onChange={(selected) => {
                            handleSelectChange(selected, "category");
                          }}
                          validate={{ required: { value: true } }}
                        />
                        <p className="text-danger" style={{ fontSize: "11px" }}>
                          {/* {selectedCategory === null ? errors.categoryError : ""} */}
                        </p>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Customer Type</Label>
                        <Select
                          name="cust_type"
                          value={selected?.customerType}
                          onChange={(selected) => {
                            handleSelectChange(selected, "cust_type");
                          }}
                          options={customerType}
                          classNamePrefix="select2-selection"
                          validate={{ required: { value: true } }}
                        />
                        <p className="text-danger" style={{ fontSize: "11px" }}>
                          {/* {selectedCustomerType === null ? errors.customertypeError : ""} */}
                        </p>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Designation</Label>
                        <Select
                          name="cust_designation"
                          value={selected?.designation}
                          onChange={(selected) => {
                            handleSelectChange(selected, "cust_designation");
                          }}
                          options={designationOptions}
                          classNamePrefix="select2-selection"
                        />
                        <p className="text-danger" style={{ fontSize: "11px" }}>
                          {/* {selectedDesignation === null ? errors.designationError : ""} */}
                        </p>
                      </div>
                    </Col>
                    {master?.designation?.label === "House" &&
                    master?.designation?.label === "Rent" ? (
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Owner Name</Label>
                          <AvField
                            name="houseowner_name"
                            placeholder="Owner Name"
                            className="form-control"
                            value={master?.houseowner_name}
                            onChange={handleInputChange}
                            type="text"
                          />
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">Name</Label>
                        <AvField
                          name="cust_name"
                          placeholder="Name"
                          type="text"
                          errorMessage="Enter name"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom02"
                          value={master?.cust_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>

                    {/* {selectedCustomerType?.type === 2 ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Commercial name</Label>
                            <AvField
                              name="shop_name"
                              placeholder="Commercial Name"
                              type="text"
                              className="form-control"
                              value={master?.shop_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )} */}

                    {/* {selectedCustomerType?.type === 2 ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Building Name</Label>
                            <AvField
                              name="building_name"
                              placeholder="Building Name"
                              type="text"
                              className="form-control"
                              value={master?.building_name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )} */}

                    {/* {selectedCustomerType?.label === "FLAT" ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Flat Name</Label>
                            <Select
                              name="flatName"
                              placeholder="Flat Name"
                              type="text"
                              onChange={(selected) => {
                                handleSelectChange(selected, "flatName");
                              }}
                              options={flatOptions}
                              value={selectedFlatname}
                              classNamePrefix="select2-seletction"
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )} */}

                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom03">
                          Mobile Number
                        </Label>
                        <AvField
                          name="cust_phone"
                          placeholder="Mobile Number"
                          type="number"
                          errorMessage="Enter valid 10 digit number"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom03"
                          // value={keyWord}
                          value={master?.cust_phone}
                          onChange={handleInputChange}
                          // onChange={handleSearchChange}
                          // onBlur={() => setFilteredData([])} // Close dropdown on blur
                          minLength={10}
                          maxLength={10}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom03">
                          Landline Number
                        </Label>
                        <AvField
                          name="cust_landline_no"
                          placeholder="Landline Number"
                          type="phone"
                          errorMessage="Enter valid 11 digit number"
                          className="form-control"
                          validate={{
                            minLength: { value: 11 },
                            maxLength: { value: 11 },
                          }}
                          id="validationCustom03"
                          value={master?.cust_landline_no}
                          onChange={handleInputChange}
                          // minLength={11}
                          // maxLength={11}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">
                          Whatsapp Number
                        </Label>
                        <AvField
                          name="cust_whatsapp_no"
                          placeholder="Whatsapp Number"
                          type="number"
                          errorMessage="Enter valid 10 digit number"
                          className="form-control"
                          validate={{
                            minLength: { value: 10 },
                            maxLength: { value: 10 },
                          }}
                          id="validationCustom01"
                          value={master?.cust_whatsapp_no}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">Email</Label>
                        <AvField
                          name="cust_email"
                          placeholder="Email"
                          type="email"
                          errorMessage="Enter valid email"
                          className="form-control"
                          validate={{ email: true }}
                          id="validationCustom01"
                          value={master?.cust_email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">Address 1</Label>
                        <AvField
                          name="cust_address"
                          placeholder="Address 1"
                          type="text"
                          errorMessage="Enter Address 1"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={master?.cust_address}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">Address 2</Label>
                        <AvField
                          name="cust_address1"
                          placeholder="Address 2"
                          type="text"
                          // errorMessage="Enter Address1"
                          className="form-control"
                          // validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={master?.cust_address1}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">
                          Number of Members
                        </Label>
                        <AvField
                          name="cust_no_members"
                          placeholder="Number of Members"
                          type="number"
                          min={1}
                          // errorMessage="Enter number of members"
                          className="form-control"
                          //validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={master?.cust_no_members}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>District</Label>
                        <Select
                          name="district"
                          value={selected?.district}
                          onChange={(selected) => {
                            handleSelectChange(selected, "district");
                          }}
                          options={districtOptions}
                          classNamePrefix="select2-selection"
                        />
                        <p className="text-danger" style={{ fontSize: "11px" }}>
                          {/* {selectedDistrict === null ? errors.districtError : ""} */}
                        </p>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward"
                          value={selected.ward}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                        <p className="text-danger" style={{ fontSize: "11px" }}>
                          {/* {selectedWard === null ? errors.wardError : ""} */}
                        </p>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Packages</Label>
                        <Select
                          name="cust_package_id"
                          value={selected.packages}
                          onChange={(selected) => {
                            handleSelectChange(selected, "cust_package_id");
                          }}
                          options={packageOptions}
                          classNamePrefix="select2-selection"
                        />
                        <p
                          className="text-danger"
                          style={{ fontSize: "11px" }}
                        ></p>
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">Group</Label>
                        <Select
                          name="cust_group_id"
                          value={selected?.group}
                          onChange={(selected) => {
                            handleSelectChange(selected, "cust_group_id");
                          }}
                          options={groupOptions}
                          classNamePrefix="select2-selection"
                        />
                        <p className="text-danger" style={{ fontSize: "11px" }}>
                          {/* {selectedGroup === null ? errors.groupError : ""} */}
                        </p>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">Building No</Label>
                        <AvField
                          name="cust_house_num"
                          placeholder="House No"
                          type="text"
                          errorMessage="Enter House no"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={master?.cust_house_num}
                          onChange={handleInputChange}

                          // onChange={handleInputChange}
                          // value={BuildingNo}
                          // onChange={handleChangeSearch}
                          // onBlur={() => setFilterData([])} // Close dropdown on blur
                        />
                      </div>
                    </Col>

                    {/* {selectedCustomerType?.label === "Flat" ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Select
                              name="flatName"
                              placeholder="Flat Name"
                              onChange={(selected) => {
                                handleSelectChange(selected, "flatName");
                              }}
                              options={flatOptions}
                              value={selectedFlatname}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )} */}

                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">Latitude</Label>
                        <AvField
                          name="cust_latitude"
                          placeholder="Latitude"
                          type="number"
                          className="form-control"
                          id="validationCustom01"
                          value={master?.cust_latitude}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">Longitude</Label>
                        <AvField
                          name="cust_longitude"
                          placeholder="Longitude"
                          type="number"
                          className="form-control"
                          id="validationCustom01"
                          value={master?.cust_longitude}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Billing Type</Label>
                        <Select
                          name="cust_billing_type"
                          value={selected.billingtype}
                          onChange={(selected) => {
                            handleSelectChange(selected, "cust_billing_type");
                          }}
                          options={[
                            {
                              label: "Monthly",
                              value: "61f775c6aef04ceabc89eae7",
                            },
                            {
                              label: "Spot",
                              value: "61f77607aef04ceabc89eae9",
                            },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                        <p
                          className="text-danger"
                          style={{ fontSize: "11px" }}
                        ></p>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom03">
                          Building Image
                        </Label>
                        {master?.cust_image ? (
                          <div className="img-wraps">
                            {master.cust_image &&
                            master.cust_image.startsWith("http") ? (
                              <img
                                alt=""
                                width="150"
                                height="150"
                                src={`${master.cust_image}`}
                              />
                            ) : (
                              <img
                                alt=""
                                width="150"
                                height="150"
                                src={`${API_URL}uploads/customers_images/${master.cust_image}`}
                              />
                            )}
                            <button
                              className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                              onClick={deleteCustomerImage}
                              style={{ width: "150px" }}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <AvField
                            name="cust_image"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            onChange={uploadImage}
                            rows="1"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Button
                    color="primary"
                    type="submit"
                    className="me-2"
                    //disabled={addingCustomer ? true : false}
                  >
                    Update
                  </Button>
                  <Button color="danger" type="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <MDBDataTable
                  id="verifyCustomerId"
                  responsive
                  bordered
                  data={data}
                  disableRetreatAfterSorting={true}
                  entries={20}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default VerifyCustomer;
